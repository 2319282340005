import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "./components/Globalstyle";
import ScrollToTop from "./components/ScrollToTop";
import { routePath } from "./constants";
import Heritage from "./pages/Heritage";
import HeritageDetail from "./pages/Heritage/Id";
import Home from "./pages/Home";
import Introduction from "./pages/Introduction";
import Network from "./pages/Network";
import Place from "./pages/Place";
import AiComu from "@pages/AiComu";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import VrDimen from "@pages/VrDimen";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
        // light: main값을 통해 계산됨
        // dark: main값을 통해 계산됨
        // contrastText: main값을 통해 계산됨
      },
    },
    components: {
      MuiInput: {
        styleOverrides: {},
      },
    },
  });

  useEffect(() => {
    AOS.init();
  });
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path={routePath.INDEX} element={<Home />} />
            <Route path={routePath.INTRODUCTION} element={<Introduction />} />
            <Route path={routePath.HERITAGE} element={<Heritage />} />
            <Route
              path={routePath.HERITAGE_DETAIL}
              element={<HeritageDetail />}
            />
            <Route path={routePath.NETWORK} element={<Network />} />
            <Route path={routePath.PLACE} element={<Place />} />
            <Route path={routePath.AICOMU} element={<AiComu />} />
            <Route path={routePath.VRDIMEN} element={<VrDimen />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
