import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
}

const IntroButton: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return <Button {...rest}>{children}</Button>;
};
export default IntroButton;

const Button = styled.button`
  width: 220px;
  height: 60px;
  background-color: ${colors.GRAY1};
  color: ${colors.WHITE};
  ${fonts("H4")};
  border: 0;
  border-radius: 50px;
  margin-top: 28px;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    background-color: ${colors.WHITE};
    color: ${colors.GRAY1};
    box-shadow: 0px 0px 0px 1px ${colors.GRAY1} inset;
  }
`;
