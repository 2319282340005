import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from ".";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import MyButton from "../Button";
import MySelect from "../MySelect";
import human from "../../assets/img/dummy/human.png";
import Id1 from "../../assets/reference/id1.jpg";
import Id3 from "../../assets/reference/id3.jpg";
import Id5 from "../../assets/reference/id5.png";
import Id6 from "../../assets/reference/id6.jpg";
import Id7 from "../../assets/reference/id7.jpg";
import Id8 from "../../assets/reference/id8.jpg";
import ContentLayout from "../ContentLayout";
import MainSearch from "../MyInput/MainSearch";
import { HeritageInfo } from "../HeritageListCard";
import Filter from "../Filter";
import RemovableLabel from "../Label/RemovableLabel";
import HeritageListCardButton from "../HeritageListCard/HeritageListCardButton";
import { SelectChangeEvent } from "@mui/material";
import { PageSizeOption } from "../../constants";
import { useMutation } from "react-query";
import { requestHeritageList } from "@apis";
import {
  IHeritageListDetailClassificationDetailCountitem,
  IHeritageListDetailCountItem,
  IHeritageListItem,
  IResponseHeritageList,
} from "@apis/heritage/interface/response";
import { AxiosError } from "axios";
import Pagination from "@components/Pagination";

interface Props {
  onClose: () => void;
  visible: boolean;
  selectedList?: IHeritageListItem[];
  onSelectedChange: (selectedList: IHeritageListItem[]) => void;
}

const AddHeritageModal: React.FC<Props> = ({
  visible,
  onClose,
  selectedList = [],
  onSelectedChange,
}) => {
  /** 검색어 */
  const [keyword, setKeyword] = useState<string>("");
  /** 문화자산 리스트 */
  const [heritageData, setHeritageData] = useState<IHeritageListItem[]>([]);
  /** 문화자산 검색 총 갯수 */
  const [dataCount, setDataCount] = useState<number>(0);
  /** 문화자산 검색 결과 상세 */
  const [detailCountData, setDetailCountData] = useState<
    IHeritageListDetailCountItem[]
  >([]);
  const [classificationDetailCountsData, setClassificationDetailCountsData] =
    useState<IHeritageListDetailClassificationDetailCountitem[]>([]);
  /** 검색 결과 true/false */
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [modalSelectedList, setModalSelectedList] = useState<
    IHeritageListItem[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [categoryFilterValue, setCategoryFilterValue] = useState<any[]>([]);

  /** 문화자산 리스트 조회 Mutation */
  const { mutate: getHeritageList } = useMutation(requestHeritageList, {
    retry: false,
    onSuccess: (data) => {
      const { row } = data;
      const { list, detailCounts, count, classificationDetailCounts } = row;
      setHeritageData(list || []);
      setDataCount(count);
      setDetailCountData(detailCounts || []);
      setClassificationDetailCountsData(classificationDetailCounts);
      setIsEmpty(true);
    },
    onError: (e: AxiosError) => {
      if (e.status === 400) {
        setHeritageData([]);
        setDataCount(0);
        setDetailCountData([]);
        setIsEmpty(true);
      }
    },
  });

  const getIsIncludeValue = (info: IHeritageListItem) => {
    for (let i = 0; i < modalSelectedList.length; i++) {
      if (modalSelectedList[i].id === info.id) {
        return true;
      }
    }
    return false;
  };

  const handlePageSizeChange = (e: SelectChangeEvent<unknown>) => {
    const pageSize = Number(e.target.value);
    setPageSize(pageSize as number);
    getHeritageList({
      types: filterValue,
      subject: keyword,
      page: currentPage,
      pageSize,
    });
  };
  const handleHeritageRemoveClick = (info: IHeritageListItem) => {
    if (getIsIncludeValue(info)) {
      setModalSelectedList((prev) =>
        prev.filter((prevInfo) => prevInfo.id !== info.id)
      );
    }
  };
  const handleHeritageClick = (info: IHeritageListItem): void => {
    if (getIsIncludeValue(info)) {
      setModalSelectedList((prev) =>
        prev.filter((prevInfo) => prevInfo.id !== info.id)
      );
    } else {
      if (modalSelectedList.length < 3) {
        setModalSelectedList((prev) => prev.concat(info));
      }
    }
  };
  const handleFilterChange = (value: any) => {
    if (filterValue.includes(value)) {
      setFilterValue((prev) => {
        const newPrev = prev.filter((prevValue) => prevValue !== value);
        onFilterHeritageList(newPrev, "filter");
        return newPrev;
      });
    } else {
      setFilterValue((prev) => {
        const newPrev = prev.concat(value);
        onFilterHeritageList(newPrev, "filter");
        return newPrev;
      });
    }
  };
  const handleFilterCategoryChange = (value: any) => {
    if (categoryFilterValue.includes(value)) {
      setCategoryFilterValue((prev) => {
        const newPrev = prev.filter((prevValue) => prevValue !== value);
        onFilterHeritageList(newPrev, "category");
        return newPrev;
      });
    } else {
      setCategoryFilterValue((prev) => {
        const newPrev = prev.concat(value);
        onFilterHeritageList(newPrev, "category");
        return newPrev;
      });
    }
  };

  const handleAddClick = () => {
    onSelectedChange(modalSelectedList);
    onClose();
  };

  const handleSearchKeyword = (text: string) => {
    getHeritageList({
      subject: text,
      types: filterValue,
      page: 1,
      pageSize: pageSize,
    });
    setCurrentPage(1);
  };

  /** 검색 Input 변경 */
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  /** Filter Selected 변경 시 실행 */
  const onFilterHeritageList = (filter: string[], type: string) => {
    if (type === "filter") {
      getHeritageList({
        subject: keyword,
        types: filter,
        classificationTypes: categoryFilterValue,
        page: 1,
        pageSize: pageSize,
      });
    } else {
      getHeritageList({
        subject: keyword,
        types: filterValue,
        classificationTypes: filter,
        page: 1,
        pageSize: pageSize,
      });
    }
    setCurrentPage(1);
  };

  /** Page 변경 시  */
  const handleChangeCurrentPage = (page: number) => {
    setCurrentPage(page);
    getHeritageList({
      subject: keyword,
      types: filterValue,
      classificationTypes: categoryFilterValue,
      page: page,
      pageSize: pageSize,
    });
  };

  useEffect(() => {
    if (visible) {
      setModalSelectedList(selectedList);
    } else {
      setModalSelectedList([]);
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClick={onClose}>
      <ModalWrap>
        <HeritageLayout>
          <HeritageSearchTop>
            <HeritageSearchTitleWrap>
              <HeritageSearchTitle>자료 검색</HeritageSearchTitle>
              <div>문화유산 및 지형의 입지 공간 변화를 살펴봅니다.</div>
            </HeritageSearchTitleWrap>
            <ContentLayout>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearchKeyword(keyword);
                }}
              >
                <HeritageSearchWrap>
                  <MainSearch
                    height="48px"
                    placeholder="검색어를 입력해 주세요"
                    value={keyword}
                    onChange={handleKeywordChange}
                  />
                  <MyButton type="submit" height="48px" width="58px">
                    검색
                  </MyButton>
                </HeritageSearchWrap>
              </form>
            </ContentLayout>
          </HeritageSearchTop>
          <HeritageSearchBody>
            <ContentLayout>
              <HeritageSearchFilter>
                <Filter
                  onChangeCategory={handleFilterCategoryChange}
                  onChange={handleFilterChange}
                  values={filterValue}
                  categoryValues={categoryFilterValue}
                />
              </HeritageSearchFilter>
            </ContentLayout>
            {heritageData.length > 0 ? (
              <HeritageSearchResultWrap>
                <HeritageSearchResultCount>
                  총 {dataCount || 0}건 검색
                </HeritageSearchResultCount>
                <HeritageSearchResultCategory>
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <div style={{ display: "flex", gap: "4px" }}>
                      {detailCountData?.map((item, idx) => (
                        <div key={idx.toString()}>
                          {idx !== 0 && " / "}
                          {item.name} {item.value}건
                        </div>
                      ))}
                    </div>
                    <HeritageSearchResultCategoryClassifi
                      style={{ display: "flex", gap: "4px" }}
                    >
                      {classificationDetailCountsData?.map((item, idx) => (
                        <div key={idx.toString()}>
                          {idx !== 0 && " / "}
                          {item.name} {item.value}건
                        </div>
                      ))}
                    </HeritageSearchResultCategoryClassifi>
                  </div>
                  <MySelect
                    width="121px"
                    options={PageSizeOption}
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  />
                </HeritageSearchResultCategory>
                <HeritageSearchResult>
                  {heritageData.map((data) => (
                    <HeritageListCardButton
                      key={data.id}
                      info={data}
                      onClick={handleHeritageClick}
                      isSelected={
                        !!data.withoutUriId && getIsIncludeValue(data)
                      }
                    />
                  ))}
                </HeritageSearchResult>
                <PaginationWrap>
                  <Pagination
                    page={currentPage}
                    pageSize={pageSize}
                    onCurrentChange={handleChangeCurrentPage}
                    totalCount={dataCount}
                    maxLength={5}
                  />
                </PaginationWrap>
              </HeritageSearchResultWrap>
            ) : isEmpty ? (
              <Empty>검색결과를 찾을 수 없습니다.</Empty>
            ) : null}
          </HeritageSearchBody>
        </HeritageLayout>
      </ModalWrap>
      <ModalFooter>
        <HeritageSelectedLabel>선택한 항목</HeritageSelectedLabel>
        <HeritageSelectedWrap>
          {modalSelectedList.map((data) => (
            <RemovableLabel
              info={data}
              onHeritageRemoveClick={handleHeritageRemoveClick}
            />
          ))}
        </HeritageSelectedWrap>
        {modalSelectedList.length >= 3 && (
          <SelectWarning>비교는 3개까지만 가능합니다.</SelectWarning>
        )}

        <MyButton
          type="button"
          height="48px"
          width="100px"
          onClick={handleAddClick}
        >
          추가완료
        </MyButton>
      </ModalFooter>
    </Modal>
  );
};
export default AddHeritageModal;

const ModalWrap = styled.div`
  width: 1184px;
  max-height: 80vh;
  padding: 32px 20px;
  overflow: auto;
`;

const HeritageLayout = styled.div`
  gap: 0 20px;
`;
const HeritageSearchTop = styled.div`
  ${fonts("B4")}
`;
const HeritageSearchTitleWrap = styled.div`
  margin-bottom: 32px;
`;
const HeritageSearchTitle = styled.h2`
  ${fonts("H3")};
`;
const HeritageSearchWrap = styled.div`
  display: flex;
  gap: 0 16px;
`;
const HeritageSearchBody = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  gap: 0 21px;
`;
const HeritageSearchFilter = styled.div`
  width: 169px;
`;
const HeritageSearchResultWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const HeritageSearchResultCount = styled.div`
  ${fonts("H3")}
`;
const HeritageSearchResultCategory = styled.div`
  display: flex;
  gap: 0 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  ${fonts("B2")}
  color:${colors.GRAY4};
`;
const HeritageSearchResult = styled.div`
  margin-top: 10px;
  > article + article {
    margin-top: 8px;
  }
`;
const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 0 32px;
  padding: 16px 32px;
  background-color: ${colors.WHITE};
  ${fonts("B3")};
`;
const HeritageSelectedLabel = styled.div`
  ${fonts("H5")};
`;
const HeritageSelectedWrap = styled.div`
  width: 300px;
  flex-grow: 1;
  gap: 0 8px;
  display: flex;
  flex-wrap: wrap;
`;
const SelectWarning = styled.div`
  color: ${colors.RED};
`;

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 383px;
  ${fonts("H3")};
  color: ${colors.GRAY5};
`;

const PaginationWrap = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px 10px;
  margin: 20px 0px;
  align-self: center;
`;

const HeritageSearchResultCategoryClassifi = styled.div`
  ${fonts("B4")};
`;
