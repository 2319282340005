import React, { useState } from "react";
import styled from "styled-components";
import MyButton from "../../components/Button";
import ContentLayout from "../../components/ContentLayout";
import Layout from "../../components/Layout";
import { PageWrap } from "../../components/Layout/styles";
import MemberInfo, { MemberInfoProps } from "../../components/MemberInfo";
import useChangePage from "../../hooks/useChangePage";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

interface Props {}

const Introduction: React.FC<Props> = () => {
  const { handleChangePage } = useChangePage();
  const [isKo, setIsKo] = useState(true);

  const MemberInfoArray: MemberInfoProps[] = [
    {
      name: "한동수",
      job: "한양대학교 건축학과 교수",
      skills: ["지역화 자료 수집 / DB 구축"],
      position: "연구책임자",
      link: "http://fahl.hanyang.ac.kr/",
    },
    {
      name: "전한종",
      job: "한양대학교 건축학과 교수",
      skills: ["플랫폼 구축 및 시각화"],
      position: "공동연구원",
      link: "https://www.adcc.hanyang.ac.kr/",
    },
    {
      name: "김영재",
      job: "한국전통문화대학교 문화재수리기술학과 교수",
      skills: ["데이터 구조 및 내러티브 분석"],
      position: "공동연구원",
    },
    {
      name: "김정희",
      job: "한양대학교 건축학과 석박사통합과정",
      skills: ["지역화 DB구축/분석"],
      position: "연구보조원",
    },
    {
      name: "김언용",
      job: "국립안동대학교 미술학과 교수",
      skills: ["플랫폼 설계", "시각 데이터 구축"],
      position: "전임연구원",
    },
    {
      name: "이왕훈",
      job: "한국전통문화대학교 문화재수리기술학과 박사과정",
      skills: ["데이터 구축/분석"],
      position: "연구보조원",
    },
    {
      name: "홍경화",
      job: "한양대학교 건축학과 박사과정",
      skills: ["지역화 DB구축/분석"],
      position: "연구보조원",
    },
    {
      name: "박종진",
      job: "한양대학교 건축학과 연구교수",
      skills: ["시각 데이터 구축/분석", "온톨로지 구축", "플랫폼 구축"],
      position: "전임연구원",
    },
    {
      name: "권순모",
      job: "한국전통문화대학교 문화재수리기술학과 석사과정",
      skills: ["데이터 구축"],
      position: "연구보조원",
    },
    {
      name: "김지훈",
      job: "한양대학교 건축학과 석사과정",
      skills: ["지역화 DB구축/분석"],
      position: "연구보조원",
    },
    {
      name: "최진우",
      job: "한양대학교 건축학과 석사과정",
      skills: ["시각 데이터 구축", "온톨로지 구축"],
      position: "연구보조원",
    },
    {
      name: "김래영",
      job: "한국전통문화대학교 문화재수리기술학과 석사과정",
      skills: ["데이터 구축"],
      position: "연구보조원",
    },
    { blank: true },
    { blank: true },
    {
      name: "김예린",
      job: "한국전통문화대학교 문화재수리기술학과 석사과정",
      skills: ["데이터 구축"],
      position: "연구보조원",
    },
    {
      name: "오신일",
      job: "한국전통문화대학교 문화재수리기술학과 석사과정",
      skills: ["데이터 구축"],
      position: "연구보조원",
    },
  ];
  const handleLanguageChangeClick = () => {
    setIsKo((prev) => !prev);
  };

  return (
    <Layout selectedMenu="project" onChangePage={handleChangePage}>
      <PageWrap>
        <ProjectInfoSection>
          <ProjectHeader>
            <h2>Korean Cultural Heritage Resources</h2>
            <MyButton width="140px" onClick={handleLanguageChangeClick}>
              {isKo ? "Read in English" : "한국어로 보기"}
            </MyButton>
          </ProjectHeader>
          <ContentLayout>
            {isKo ? (
              <>
                <ProjectAdditionalInfo>
                  <div>
                    지역역사문화자산의 재해석을 위한 AI-아카이브 플랫폼 구축
                    프로젝트
                  </div>
                  <div>(2021.06 ~ 2024.05)</div>
                </ProjectAdditionalInfo>
                <ProjectInfoContent>
                  <p>
                    본 연구는 역사와 문화자산이 풍부한 강화도를 대상으로
                    역사문화자산 정보를 재해석하고 새로운 역사적 연계성과 가치를
                    발견할 수 있는 AI-아카이브 플랫폼 구축을 목표로 하고
                    있습니다.
                  </p>
                  <p>
                    이를 통해 지역성을 명확하게 정의하고 수용할 수 있는 정보의
                    네트워크를 강화하고, 지역 공간 정보를 재편성하여 집단 기억에
                    기반한 지역성을 재해석하고자 합니다.
                  </p>
                  <p>
                    이를 위해서 지역역사문화자산 정보를 메타데이터 기반의
                    머신리더블(Machine-readable) 데이터셋으로 구축하고,
                    온톨로지(Ontology)를 활용하여 정보의 관계성을 구축하여
                    역사적 데이터를 인문학 관점에서 새롭게 분석하고 재해석 할 수
                    있는 정보의 네트워크 체계를 구축합니다. 이렇게 구축된
                    데이터베이스의 정보는 머신러닝(Machine Learning)을 활용하여
                    정보의 상관관계를 분석하고 특성을 추출하여 새로운
                    내러티브(Narrative) 구조를 도출할 수 있습니다. 또한, 다양한
                    지역역사문화 자산 정보 사이에서 지역성을 파악할 수 있는
                    새로운 정보 네트워크를 구축하고, 공간이나 시점 등 다각적
                    층위에서 분석된 유기적 연결고리를 통해서 지역성과 관련된
                    연구의 확대를 기대할 수 있습니다. 마지막으로 다양한 형태의
                    지역역사문화자산 관련 데이터를 통합할 수 있는 아카이브
                    메타데이터 스키마 모델을 구축하면, 이를 확장하여 건축,
                    지역학, 문화 등과 같은 다양한 분야에 최적화된 플랫폼을
                    구축할 수 있습니다.
                  </p>
                </ProjectInfoContent>
              </>
            ) : (
              <>
                <ProjectAdditionalInfo>
                  <div>
                    Building the AI-Archive Platforms for the Re-interpretation
                    of Local Historical Cultural Resources
                  </div>
                  <div>(2021.06 ~ 2024.05)</div>
                </ProjectAdditionalInfo>
                <ProjectInfoContent>
                  <p>
                    This research project aims to establish an Ai-archive
                    platform for re-interpreting cultural asset information and
                    discovering new historical connections and values from local
                    historical and cultural resources in Ganghwa Island.
                  </p>
                  <p>
                    Through this, our team would like to reinforce the network
                    of information that can clearly define and accommodate
                    locality, and reorganize local spatial information to
                    reinterpret locality based on collective memory.
                  </p>
                  <p>
                    To this end, our team collect local historical and cultural
                    asset information and transform it into a machine-readable
                    dataset based on metadata, and create an information network
                    using ontologies that can assess and reinterpret historical
                    data from a humanities perspective. The information from the
                    proposed database can be used to analyze the correlation
                    between local historical and cultural resources and extract
                    features to derive a new narrative structure. In addition,
                    it is expected that a new information network can be
                    established to identify locality among various local
                    historical and cultural assets, and that locality-related
                    research can be further expanded through organic connections
                    at various levels such as space and time. Finally, by
                    building a metadata schema model for archiving various forms
                    of local historical and cultural assets, it is expected to
                    establish an optimized platform for various fields, such as
                    architecture, regional and cultural studies.
                  </p>
                </ProjectInfoContent>
              </>
            )}
          </ContentLayout>
        </ProjectInfoSection>
        <MemberInfoSection>
          <DetailTitle>프로젝트 구성원</DetailTitle>
          <MemberListWrap>
            {MemberInfoArray.map((info) => {
              return <MemberInfo info={info} />;
            })}
          </MemberListWrap>
        </MemberInfoSection>
      </PageWrap>
    </Layout>
  );
};
export default Introduction;

const ProjectInfoSection = styled.section`
  padding-top: 42px;
`;
const DetailTitle = styled.h2`
  ${fonts("H2")};
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;
const ProjectAdditionalInfo = styled.div`
  display: flex;
  gap: 0 8px;
  > div:nth-child(1) {
    ${fonts("H5")};
    color: ${colors.GRAY3};
  }
  > div:nth-child(2) {
    ${fonts("B3")};
    color: ${colors.GRAY5};
  }
`;
const ProjectInfoContent = styled.div`
  margin-top: 16px;
  ${fonts("B3")};
  color: ${colors.GRAY3};
`;
const MemberInfoSection = styled.section`
  margin-top: 80px;
`;
const MemberListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 24px;
`;
