import React, { ReactNode, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Prev } from "../../assets/img/icon/left.svg";
import { ReactComponent as Next } from "../../assets/img/icon/right.svg";
import { colors } from "../../theme/colors";

// TODO 이미지들을 밖에서 받아야 할듯

export interface ImageSlideOptions {
  slideWidth?: number;
  imageWidth?: number;
  gap?: number;
  children: ReactNode;
}

const ImageSlide: React.FC<ImageSlideOptions> = ({
  imageWidth = 170,
  gap = 8,
  children,
  slideWidth = 560,
}) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const TRANSFORM_AMOUT = imageWidth + gap;

  const [transform, setTransform] = useState(0);

  const handleClickPrev = () => {
    if (slideRef.current && transform > 0) {
      slideRef.current.style.transform = `translate(${-(
        (transform - 1) *
        TRANSFORM_AMOUT
      )}px)`;
      setTransform((prev) => prev - 1);
    }
  };
  const handleClickNext = () => {
    if (slideRef.current) {
      const childCount = slideRef.current.childElementCount;
      if (
        transform <
        childCount -
          Math.floor((slideWidth - (gap * childCount - 1)) / imageWidth)
      ) {
        slideRef.current.style.transform = `translate(${-(
          (transform + 1) *
          TRANSFORM_AMOUT
        )}px)`;
        setTransform((prev) => prev + 1);
      }
    }
  };
  return (
    <Slide>
      <PrevButton onClick={handleClickPrev}>
        <PrevIcon />
      </PrevButton>
      <ImageListWrap style={{ width: slideWidth }}>
        <ImageList ref={slideRef} gap={gap}>
          {children}
        </ImageList>
      </ImageListWrap>
      <NextButton onClick={handleClickNext}>
        <NextIcon />
      </NextButton>
    </Slide>
  );
};
export default ImageSlide;

const Slide = styled.div`
  position: relative;
`;
const PrevButton = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 50%;
  border: 1px solid ${colors.GRAY1};
  cursor: pointer;
  background-color: ${colors.WHITE};
`;
const PrevIcon = styled(Prev)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
`;
const NextButton = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 50%;
  border: 1px solid ${colors.GRAY1};
  cursor: pointer;
  background-color: ${colors.WHITE};
`;
const NextIcon = styled(Next)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
`;
const ImageListWrap = styled.div`
  overflow: hidden;
`;
const ImageList = styled.div<{ gap: number }>`
  display: flex;
  gap: ${({ gap }) => `0 ${gap}px`};
  transition: 1s;
  > * {
    flex-shrink: 0;
  }
`;
