import { Button as MuiButton, ButtonTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from "react";

interface Props extends DefaultComponentProps<ButtonTypeMap<{}, "button">> {
  children: string;
  width?: string;
  height?: string;
  fontSize?: string;
}

const Button: React.FC<Props> = ({
  children,
  width = "100%",
  height,
  fontSize = "14px",
  ...rest
}) => {
  return (
    <MuiButton
      {...rest}
      sx={{
        height: height ?? "32px",
        fontSize,
        textTransform: "none",
        fontWeight: 500,
        width,
        wordBreak: "keep-all",
      }}
      color="primary"
      variant="contained"
    >
      {children}
    </MuiButton>
  );
};
export default Button;
