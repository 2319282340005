import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { PageWrap } from "../../components/Layout/styles";
import useChangePage from "../../hooks/useChangePage";
import { fonts } from "../../theme/fonts";
import MyButton from "../../components/Button";
import ContentLayout from "../../components/ContentLayout";
import { colors } from "../../theme/colors";
import MySelect from "../../components/MySelect";
import HeritageListCard from "../../components/HeritageListCard";
import MainSearch from "../../components/MyInput/MainSearch";
import Filter from "../../components/Filter";
import { SelectChangeEvent } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { PageSizeOption } from "../../constants";
import { useQuery } from "react-query";
import { requestHeritageList } from "@apis";
import {
  IHeritageListDetailClassificationDetailCountitem,
  IHeritageListDetailCountItem,
  IHeritageListItem,
} from "@apis/heritage/interface/response";
import { AxiosError } from "axios";
import Pagination from "@components/Pagination";

interface Props {}

const Heritage: React.FC<Props> = () => {
  /** 페이지 이동 hook */
  const { handleChangePage } = useChangePage();
  /** 검색 keyword with Input */
  const [keyword, setKeyword] = useState("");
  /** 검색 결과 및 필터 검색에 대한 param getter */
  const [searchParam, setSearchParam] = useSearchParams();
  /** 검색 결과 리스트 담는 곳 */
  const [data, setData] = useState<IHeritageListItem[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);
  const [detailCountData, setDetailCountData] = useState<
    IHeritageListDetailCountItem[]
  >([]);
  const [classificationDetailCountsData, setClassificationDetailCountsData] =
    useState<IHeritageListDetailClassificationDetailCountitem[]>([]);
  /** 검색 결과 없을 시 true */
  /** 서버 붙을 시 useEffect 제어 제거 및 이 주석 삭제할 것 */
  /** 서버가 붙은 후 data.length 가 []로 내려올 시 empty 자체 제거 */
  const [isEmpty, setIsEmpty] = useState(false);
  /** 페이지네이션 페이지 */
  const [currentPage, setCurrentPage] = useState(1);
  /** 검색 결과 페이지 사이즈 조정 - default 10개 */
  const [pageSize, setPageSize] = useState(10);
  // 여기 서버 붙으면 필터 안으로 이동

  /** 문화 자산 리스트 조회 API */
  useQuery(
    [
      "heritageList",
      JSON.parse(searchParam.get("filter") as string)?.checkList,
      JSON.parse(searchParam.get("filter") as string)?.keyword,
      JSON.parse(searchParam.get("filter") as string)?.pageSize,
      JSON.parse(searchParam.get("filter") as string)?.page,
      JSON.parse(searchParam.get("filter") as string)?.categoryList,
    ],
    () =>
      requestHeritageList({
        types: JSON.parse(searchParam.get("filter") as string)?.checkList || [],
        subject: JSON.parse(searchParam.get("filter") as string)?.keyword || "",
        page: JSON.parse(searchParam.get("filter") as string)?.page,
        pageSize: JSON.parse(searchParam.get("filter") as string)?.pageSize,
        classificationTypes: JSON.parse(searchParam.get("filter") as string)
          ?.categoryList,
        isHeritageSearchable: true,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { row } = data;
        const { list, detailCounts, count, classificationDetailCounts } = row;
        setData(list);
        setDataCount(count);
        setDetailCountData(detailCounts);
        setClassificationDetailCountsData(classificationDetailCounts);
        if (list.length === 0) {
          setIsEmpty(true);
        }
      },
      onError: (e: AxiosError) => {
        if (e.status === 400) {
          setData([]);
          setDataCount(0);
          setDetailCountData([]);
          setIsEmpty(true);
        }
      },
    }
  );

  /** 검색 결과 내 필터 조회 기능 제공 */
  const handleFilterChange = (value: any) => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    let checkList = filterValues?.checkList || [];
    let categoryList = filterValues?.categoryList || [];

    if (checkList.includes(value)) {
      checkList = checkList.filter((prevValue: string) => prevValue !== value);
      setSearchParam({
        filter: JSON.stringify({
          ...filterValues,
          checkList,
          categoryList,
          page: 1,
          pageSize,
        }),
      });
    } else {
      checkList.push(value);
      setSearchParam({
        filter: JSON.stringify({
          ...filterValues,
          checkList,
          categoryList,
          page: 1,
          pageSize,
        }),
      });
    }
  };

  /** 검색 결과 내 필터 카테고리 조회 기능 */
  const handleFilterCategoryChange = (value: any) => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    let checkList = filterValues?.checkList || [];
    let categoryList = filterValues?.categoryList || [];
    if (categoryList.includes(value)) {
      categoryList = categoryList.filter(
        (prevValue: string) => prevValue !== value
      );
      setSearchParam({
        filter: JSON.stringify({
          ...filterValues,
          checkList,
          categoryList,
          page: 1,
          pageSize,
        }),
      });
    } else {
      categoryList.push(value);
      setSearchParam({
        filter: JSON.stringify({
          ...filterValues,
          checkList,
          categoryList,
          page: 1,
          pageSize,
        }),
      });
    }
  };

  /** 검색 결과 페이지 사이즈 변경 10개 - 20개 - 30개 */
  const handlePageSizeChange = (e: SelectChangeEvent<unknown>) => {
    setPageSize(e.target.value as number);
  };

  /** 검색 Input 변경 */
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  /** 검색 결과 내 필터 Option Getter */
  const getOptionValue = () => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    return filterValues?.checkList || [];
  };

  const getOptionCategoryValue = () => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    return filterValues?.categoryList || [];
  };

  /** 검색 결과 Result Setting */
  const getResult = (keyword: string) => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    const checkList = filterValues?.checkList || [];
    const categoryList = filterValues?.categoryList || [];
    setSearchParam({
      filter: JSON.stringify({
        keyword,
        checkList,
        categoryList,
        page: 1,
        pageSize,
      }),
    });
  };

  const handleCurrentPageChange = (num: number) => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    const checkList = filterValues?.checkList || [];
    const categoryList = filterValues?.categoryList || [];
    setSearchParam({
      filter: JSON.stringify({
        keyword,
        checkList: checkList,
        categoryList,
        page: num,
        pageSize,
      }),
    });
  };

  useEffect(() => {
    const filterValues = JSON.parse(searchParam.get("filter") as string);
    if (filterValues) {
      const { keyword = "", page, pageSize } = filterValues;
      setKeyword(keyword);
      setCurrentPage(page);
      setPageSize(pageSize);
    } else {
      setKeyword("");
      setCurrentPage(1);
      setPageSize(10);
    }
  }, [searchParam]);

  return (
    <Layout selectedMenu="heritage" onChangePage={handleChangePage}>
      <PageWrap>
        <HeritageLayout>
          <HeritageSearchTop>
            <HeritageSearchTitleWrap>
              <HeritageSearchTitle>문화자산 검색</HeritageSearchTitle>
              <div>
                문화유산, 인물, 사건 등 지역 역사문화 관련 자료를 검색할 수
                있습니다.
              </div>
            </HeritageSearchTitleWrap>
            <ContentLayout padding={22}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getResult(keyword);
                }}
              >
                <HeritageSearchWrap>
                  <MainSearch
                    height="48px"
                    placeholder="검색어를 입력해 주세요"
                    value={keyword}
                    onChange={handleKeywordChange}
                  />
                  <MyButton type="submit" height="48px" width="58px">
                    검색
                  </MyButton>
                </HeritageSearchWrap>
              </form>
            </ContentLayout>
          </HeritageSearchTop>
          <HeritageSearchBody>
            <ContentLayout>
              <HeritageSearchFilter>
                <Filter
                  onChange={handleFilterChange}
                  values={getOptionValue()}
                  categoryValues={getOptionCategoryValue()}
                  onChangeCategory={handleFilterCategoryChange}
                />
              </HeritageSearchFilter>
            </ContentLayout>
            {data.length > 0 ? (
              <>
                <HeritageSearchResultWrap>
                  <HeritageSearchResultCount>
                    총 {dataCount}건 검색
                  </HeritageSearchResultCount>
                  <HeritageSearchResultCategory>
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <div style={{ display: "flex", gap: "4px" }}>
                        {detailCountData?.map((item, idx) => (
                          <div key={idx.toString()}>
                            {idx !== 0 && " / "}
                            {item.name} {item.value}건
                          </div>
                        ))}
                      </div>
                      <HeritageSearchResultCategoryClassifi
                        style={{ display: "flex", gap: "4px" }}
                      >
                        {classificationDetailCountsData?.map((item, idx) => (
                          <div key={idx.toString()}>
                            {idx !== 0 && " / "}
                            {item.name} {item.value}건
                          </div>
                        ))}
                      </HeritageSearchResultCategoryClassifi>
                    </div>
                    {/* {data.length === 2 ? (
                      <div>인물 2건</div>
                    ) : (
                      <div>인물 2건 / 기관 1건 / 개체 2건 / 종교신앙 3건</div>
                    )} */}
                    <MySelect
                      width="121px"
                      options={PageSizeOption}
                      value={pageSize}
                      onChange={handlePageSizeChange}
                    />
                  </HeritageSearchResultCategory>
                  <HeritageSearchResult>
                    {data.map((data) => (
                      <HeritageListCard isLink key={data.id} info={data} />
                    ))}
                  </HeritageSearchResult>
                  <PaginationWrap>
                    <Pagination
                      page={currentPage}
                      pageSize={pageSize}
                      onCurrentChange={handleCurrentPageChange}
                      totalCount={dataCount}
                      maxLength={5}
                    />
                  </PaginationWrap>
                </HeritageSearchResultWrap>
              </>
            ) : isEmpty ? (
              <Empty>검색결과를 찾을 수 없습니다.</Empty>
            ) : null}
          </HeritageSearchBody>
        </HeritageLayout>
      </PageWrap>
    </Layout>
  );
};
export default Heritage;

const HeritageLayout = styled.div`
  padding-top: 42px;
  gap: 0 20px;
`;
const HeritageSearchTop = styled.div`
  ${fonts("B4")}
`;
const HeritageSearchTitleWrap = styled.div`
  ${fonts("B3")};
  margin-bottom: 32px;
`;
const HeritageSearchTitle = styled.h2`
  ${fonts("H3")};
  margin-bottom: 12px;
`;
const HeritageSearchWrap = styled.div`
  display: flex;
  gap: 0 16px;
`;
const HeritageSearchBody = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  gap: 0 21px;
`;
const HeritageSearchFilter = styled.div`
  width: 169px;
`;
const HeritageSearchResultWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const HeritageSearchResultCount = styled.div`
  ${fonts("H3")}
`;
const HeritageSearchResultCategory = styled.div`
  display: flex;
  gap: 0 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  ${fonts("B2")}
  color:${colors.GRAY4};
`;

const HeritageSearchResultCategoryClassifi = styled.div`
  ${fonts("B4")};
`;

const HeritageSearchResult = styled.div`
  margin-top: 10px;
  > * + * {
    margin-top: 8px;
  }
`;
const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 383px;
  ${fonts("H3")};
  color: ${colors.GRAY5};
`;

const PaginationWrap = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px 10px;
  margin: 20px 0px;
  align-self: center;
`;
