import { OutlinedTextFieldProps, TextField } from "@mui/material";
import React from "react";

interface Props extends Omit<OutlinedTextFieldProps, "variant"> {
  height?: string;
}

const MainSearch: React.FC<Props> = ({ height, ...rest }) => {
  return (
    <TextField
      {...rest}
      variant="standard"
      sx={{
        height: height ?? "32px",
        width: "100%",
        fontSize: "18px",
        fontWeight: "700",
        "::placeholder": { fontSize: "18px" },
      }}
      InputProps={{
        sx: {
          height: height ?? 32,
          fontSize: "18px",
          fontWeight: "700",
          border: "0",
        },
        disableUnderline: true,
      }}
    />
  );
};
export default MainSearch;
