import Layout from "@components/Layout";
import React, { useEffect, useRef, useState } from "react";
import useChangePage from "../../hooks/useChangePage";
import { PageWrap } from "@components/Layout/styles";
import styled from "styled-components";
import { fonts } from "theme/fonts";
import Button from "@components/Button";
import { colors } from "theme/colors";
import AiAvatar from "@assets/img/ai-avatar.png";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import useAiComu from "services/ai-comu";
import { useMutation } from "react-query";
import { HttpError } from "models/HttpError";
import Lottie from "react-lottie";
import LottieLoading from "@assets/lottie/loading.json";

interface Props {}

const AiComu: React.FC<Props> = () => {
  const { handleChangePage } = useChangePage();
  const [keyword, setKeyword] = useState<string>("");
  const { chat, setChat, setAiMessage, clearMessage } = useAiComu(
    (state) => state
  );
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatWrapRef = useRef<HTMLDivElement | null>(null);

  const [output, setOutput] = useState<string>();

  const { mutateAsync: getOpenAiMessages, isLoading } = useMutation(
    async (content: { message: string; index?: number }) => {
      const { message, index } = content;
      let accumulatedOutput = "";
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ROOT_URL}open-ai/chunk-messages`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ message }),
          }
        );
        if (response.ok) {
          const textDecoder = new TextDecoder();
          const reader = response?.body?.getReader();

          if (reader)
            while (true) {
              const { done, value } = await reader?.read();

              if (done) {
                setOutput("");
                setAiMessage([
                  {
                    type: "ai",
                    message: accumulatedOutput,
                    createdDate: dayjs().locale("ko").format("A hh:mm"),
                    id: (index || chat.length) + 1,
                    status: "complete",
                  },
                ]);
                break;
              }

              // Uint8Array를 문자열로 디코딩
              const decodedString = textDecoder.decode(value);
              accumulatedOutput += decodedString;
              setOutput(accumulatedOutput);
              if (typeof accumulatedOutput === "string") {
                setAiMessage([
                  {
                    type: "ai",
                    message: accumulatedOutput,
                    createdDate: dayjs().locale("ko").format("A hh:mm"),
                    id: (index || chat.length) + 1,
                    status: "read",
                  },
                ]);
              }
            }

          return accumulatedOutput;
        } else {
          const itIsError = await response.json();
          setAiMessage([
            {
              type: "error",
              message: itIsError.message || "다시 시도해주세요.",
              createdDate: dayjs().locale("ko").format("A hh:mm"),
              id: chat.length,
              status: "complete",
            },
          ]);
        }
      } catch (e: any) {
        if (!e.status) {
          setAiMessage([
            {
              type: "ai",
              message: accumulatedOutput,
              createdDate: dayjs().locale("ko").format("A hh:mm"),
              id: (index || chat.length) + 1,
              status: "complete",
            },
          ]);
        }
        throw e;
      }
    },
    {
      // onSuccess: (data) => {
      //   setOutput("");
      // },
      onError: (error: any) => {
        if (error.status) {
          setAiMessage([
            {
              type: "error",
              message: error.message || "다시 시도해주세요.",
              createdDate: dayjs().locale("ko").format("A hh:mm"),
              id: chat.length,
              status: "complete",
            },
          ]);
        }
      },
    }
  );

  const handleResizeHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current?.scrollHeight - 20 + "px";
    }
    setTimeout(() => {
      if (chatContainerRef.current && textareaRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  const handleKeywordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setKeyword(e.target.value);
  };

  const handleResult = async (keyword: string) => {
    if (keyword !== "") {
      setChat([
        {
          type: "user",
          message: keyword,
          createdDate: dayjs().locale("ko").format("A hh:mm"),
          id: chat.length,
          status: "complete",
        },
      ]);
      setKeyword("");
      await getOpenAiMessages({ message: keyword, index: chat.length });
      // getOpenAiMessages({ message: keyword });
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = "28px";
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (chatContainerRef.current && textareaRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 50);
  }, [chat, output]);

  return (
    <Layout selectedMenu={"aicomu"} onChangePage={handleChangePage}>
      <PageWrap>
        {/* <Button onClick={handleTest}>버튼 테스트</Button>
        {output} */}
        <AiComukPageWrap>
          <AiComuPageTitle>
            <h2>AI 대화</h2>
            <span>AI 대화를 통해 자세한 정보를 검색해보세요.</span>
          </AiComuPageTitle>
          <ChatWrap id="chat-wrap">
            <Button onClick={() => clearMessage()}>초기화</Button>
            <ChatWrap2
              ref={chatWrapRef}
              height={textareaRef.current?.style.height ?? "28px"}
            >
              <ChatContainer ref={chatContainerRef} id="chat-form">
                {chat.map((item) => {
                  if (item.type === "ai") {
                    return (
                      <ChatAIMessageWrap key={item.id}>
                        <AiAvatarWrap>
                          <img src={AiAvatar} alt="ai-avatar" />
                        </AiAvatarWrap>
                        <ChatAIMessage>
                          <MessageP>{item.message}</MessageP>
                        </ChatAIMessage>
                        <ChatTime>{item.createdDate}</ChatTime>
                      </ChatAIMessageWrap>
                    );
                  }

                  if (item.type === "error") {
                    return (
                      <ChatAIMessageWrap key={item.id}>
                        <AiAvatarWrap>
                          <img src={AiAvatar} alt="ai-avatar" />
                        </AiAvatarWrap>
                        <ChatAIMessage
                          style={{ border: `1px solid ${colors.RED}` }}
                        >
                          <MessageP style={{ color: colors.RED }}>
                            {item.message}
                          </MessageP>
                        </ChatAIMessage>
                        <ChatTime>{item.createdDate}</ChatTime>
                      </ChatAIMessageWrap>
                    );
                  }

                  return (
                    <ChatUserMessageWrap key={item.id}>
                      <ChatUserMessage>
                        <MessageP>{item.message}</MessageP>
                      </ChatUserMessage>
                      <ChatTime>{item.createdDate}</ChatTime>
                    </ChatUserMessageWrap>
                  );
                })}
                {isLoading && !output && (
                  <ChatAIMessageWrap>
                    <AiAvatarWrap>
                      <img src={AiAvatar} alt="ai-avatar" />
                    </AiAvatarWrap>
                    <ChatAIMessage>
                      <MessageP style={{ height: 24 }}>
                        <Lottie options={{ animationData: LottieLoading }} />{" "}
                      </MessageP>
                    </ChatAIMessage>
                    <ChatTime>
                      {dayjs().locale("ko").format("A hh:mm")}
                    </ChatTime>
                  </ChatAIMessageWrap>
                )}
                {/* {output && (
                  <ChatAIMessageWrap>
                    <AiAvatarWrap>
                      <img src={AiAvatar} alt="ai-avatar" />
                    </AiAvatarWrap>
                    <ChatAIMessage>
                      <MessageP>{output}</MessageP>
                    </ChatAIMessage>
                    <ChatTime>
                      {dayjs().locale("ko").format("A hh:mm")}
                    </ChatTime>
                  </ChatAIMessageWrap>
                )} */}
              </ChatContainer>
            </ChatWrap2>
            <ChatSubmitForm
              id="chat-submit"
              onSubmit={(e) => {
                e.preventDefault();
                handleResult(keyword);
              }}
            >
              <ChatTextarea
                rows={1}
                ref={textareaRef}
                value={keyword}
                onChange={(e) => {
                  handleResizeHeight();
                  handleKeywordChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.shiftKey == false) {
                    e.preventDefault();
                    if (textareaRef.current) {
                      handleResult(keyword);
                    }
                  }
                }}
              />
              <Button type="submit" height="48px" width="83">
                질문하기
              </Button>
            </ChatSubmitForm>
          </ChatWrap>
        </AiComukPageWrap>
      </PageWrap>
    </Layout>
  );
};

export default AiComu;

const AiComukPageWrap = styled.div`
  padding-top: 42px;
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

const AiComuPageTitle = styled.div`
  ${fonts("B3")};
  h2 {
    ${fonts("H3")};
    margin-bottom: 12px;
  }
`;

const ChatWrap = styled.section`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  overflow: hidden;
  border-radius: 16px;
  position: relative;
`;

const ChatWrap2 = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;
  min-height: calc(
    100vh -
      ${({ height }) =>
        `${Number(height.replace("px", "")) >= 200 ? "200px" : height}`} - 355px
  );
  max-height: calc(
    100vh -
      ${({ height }) =>
        `${Number(height.replace("px", "")) >= 200 ? "200px" : height}`} - 355px
  );
  width: 100%;
`;

const ChatSubmitForm = styled.form`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 0 16px;
  padding: 16px 16px 16px 24px;
  border-top: solid 1px ${colors.GRAY7};
  background: ${colors.WHITE};
`;

const ChatTextarea = styled.textarea`
  width: 100%;
  max-height: 200px;
  border: 0;
  ${fonts("H4")};
  // height: 100%;
  padding: 10px;
  -webkit-tap-highlight-color: transparent;
  resize: none;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 36px 24px;
  overflow: auto;
  max-height: calc(100% - 80px);
`;

const ChatUserMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
  align-items: flex-end;
  gap: 4px;
`;

const ChatUserMessage = styled.div`
  padding: 8px 16px;
  background: ${colors.GRAY10};
  border-radius: 20px;
`;

const ChatTime = styled.div`
  padding: 4px 0px;
  white-space: nowrap;
`;

const ChatAIMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

const ChatAIMessage = styled.div`
  padding: 8px 16px;
  background: ${colors.WHITE};
  border-radius: 20px;
  border: 1px solid ${colors.GRAY8};
`;

const AiAvatarWrap = styled.div`
  background: linear-gradient(90deg, #5aa88a 0%, #7cc4aa 100%);
  border-radius: 20px;
  display: flex;
  padding: 4px;
  margin-right: 4px;
`;

const MessageP = styled.p`
  white-space: pre-wrap;
  word-break: break-word;
`;
