import React from "react";
import Select from "@mui/material/Select";
import { MenuItem, SelectProps } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../theme/colors";

interface Props extends SelectProps {
  options: { label: string; value: any }[];
  width?: string;
}

const MySelect: React.FC<Props> = ({ width, options, ...rest }) => {
  const getLabel = (value: any) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i].label;
      }
    }
  };
  return (
    <Select
      {...rest}
      displayEmpty
      sx={{ "input::placeholder": { fontSize: "20px" } }}
      style={{
        height: "32px",
        width: width ?? "100%",
        backgroundColor: colors.WHITE,
      }}
      renderValue={(selected) => {
        const selectedValue = selected as any;
        if (selectedValue === "") {
          return <PlaceHolder>{rest.placeholder}</PlaceHolder>;
        }

        return <SelectedValue>{getLabel(selectedValue)}</SelectedValue>;
      }}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
export default MySelect;

const PlaceHolder = styled.span`
  color: ${colors.GRAY5};
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
`;
const SelectedValue = styled.span`
  color: ${colors.GRAY1};
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
`;
