import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HashPage } from ".";
import network from "../../assets/bg/network.png";
import IntroButton from "../../components/Button/IntroButton";

interface Props {
  hash: HashPage;
}

const Network: React.FC<Props> = ({ hash }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (hash === "#page5") {
      setTimeout(() => {
        if (imageRef.current) {
          imageRef.current.style.animationName = "sizeUp";
        }
        if (textRef.current) {
          textRef.current.style.animationName = "slideIn";
        }
      }, 200);
    }
  }, [hash]);
  const handleClickDetail = () => {
    navigation("/network");
  };
  return (
    <Background>
      <NetworkImage ref={imageRef} src={network} />
      <NetworkTitleWrap ref={textRef}>
        <NetworkTitle>네트워크</NetworkTitle>
        <NetworkInfo>
          다수의 문화자산 사이의 정보 연결 관계를 관계분석 네트워크 지도와
          <br />
          워드 클라우드 분석 등을 통해서 확인할 수 있습니다.
        </NetworkInfo>
        <IntroButton onClick={handleClickDetail}>더 알아보기</IntroButton>
      </NetworkTitleWrap>
    </Background>
  );
};
export default Network;

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;
const NetworkImage = styled.img`
  position: absolute;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  @keyframes sizeUp {
    from {
      width: 100%;
    }
    to {
      width: 130%;
    }
  }
`;
const NetworkTitleWrap = styled.div`
  width: 930px;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  opacity: 0;
  z-index: 5;
  @keyframes slideIn {
    from {
      top: 60%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
  color: white;
`;
const NetworkTitle = styled.h2`
  display: inline-block;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
`;
const NetworkInfo = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
`;
