import React, { useEffect } from "react";
import { Network, DataSet, Node, Edge, Options } from "vis-network/standalone";

export interface GraphData {
  nodes: DataSet<Node, "id">;
  edges: any;
}

interface Props {
  nodes: DataSet<Node, "id">;
  edges: DataSet<Edge, "id">;
}

const NGraph: React.FC<Props> = ({ nodes, edges }) => {
  const data = {
    nodes: nodes,
    edges: edges,
  };

  // create an array with edges

  const options: Options = {
    height: "700px",
    width: "1060px",
    nodes: {
      chosen: {
        node: (values) => {
          values.color = "#2D3F4B";
          values.borderColor = "#000";
          return false;
        },
        label: false,
      },
      borderWidthSelected: 0,
      borderWidth: 0,
      color: {
        background: "#6C898D",
        border: "transparent",
        highlight: {
          background: "#2D3F4B",
          border: "rgba(8, 62, 100, 1)",
        },
        hover: {
          background: "#2D3F4B",
        },
      },
      shadow: {
        enabled: false,
      },
      // shape: "circle",
      widthConstraint: {
        minimum: 60,
        maximum: 60,
      },
      font: {
        face: "courier new",
        color: "white",
        size: 12,
        align: "center",
      },
    },
    edges: {
      color: {
        highlight: "#5AA88A",
        inherit: false,
        hover: "#2D3F4B",
      },
      arrows: {
        to: {
          enabled: true,
          type: "arrow",
        },
      },
      font: {
        face: "courier new",
      },
    },
    interaction: {
      hover: true,
      hoverConnectedEdges: true,
      selectConnectedEdges: false,
    },
    physics: {
      // simulationDuration: 10,
      // disablePhysicsAfterInitialSimulation: false,
      repulsion: {
        nodeDistance: 100,
        centralGravity: 0.2,
        springLength: 200,
        springConstant: 1,
        damping: 1,
      },
      minVelocity: 5,
      barnesHut: {
        centralGravity: 1,
        gravitationalConstant: -10000,
        springLength: 95,
        damping: 0.09,
        avoidOverlap: 0.1,
      },
      solver: "barnesHut",
      enabled: true,
      adaptiveTimestep: true,
      stabilization: {
        enabled: true,
        iterations: 1,
      },
    },
  };
  // createTest();
  useEffect(() => {
    const container = document.getElementById("network");
    if (container) {
      const network = new Network(container, data, options);
    }
  }, [data]);
  return (
    <div>
      <div id="network" />
    </div>
  );
};
export default React.memo(NGraph);
