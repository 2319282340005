import { useNavigate } from "react-router-dom";
import { Page } from "../components/Layout";
import { routePath } from "../constants";

const useControllPage = () => {
  const navicate = useNavigate();

  const handleChangePage = (page: Page) => {
    switch (page) {
      case "project":
        navicate(routePath.INTRODUCTION);
        break;
      case "heritage":
        navicate(routePath.HERITAGE);
        break;
      case "place":
        navicate(routePath.PLACE);
        break;
      case "network":
        navicate(routePath.NETWORK);
        break;
      case "aicomu":
        navicate(routePath.AICOMU);
        break;
      case "vrdimen":
        navicate(routePath.VRDIMEN);
        break;
      default:
        break;
    }
  };
  return { handleChangePage };
};
export default useControllPage;
