import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";

interface Props {
  children: ReactNode;
  visible: boolean;
}

const Portal: React.FC<Props> = ({ children, visible }) => {
  const el = document.querySelector("#modal");

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [visible]);
  if (visible && el) {
    return ReactDOM.createPortal(children, el);
  }
  return null;
};
export default Portal;
