import { Checkbox } from "@mui/material";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import MyButton from "../../components/Button";
import Layout from "../../components/Layout";
import { PageWrap } from "../../components/Layout/styles";
import useChangePage from "../../hooks/useChangePage";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { ReactComponent as ResetIcon } from "../../assets/img/icon/reset.svg";
import ImageSlide from "../../components/ImageSlide";
import InfoLabelGroup, { Label } from "../../components/InfoLabelGroup";
import IconImageHiresOff from "@assets/img/icon/imageHires_off.png";
import IconImageHiresOn from "@assets/img/icon/imageHires_on.png";
import IconModel3DOff from "@assets/img/icon/model3D_off.png";
import IconModel3DOn from "@assets/img/icon/model3D_on.png";
import IconPcdOff from "@assets/img/icon/pcd_off.png";
import IconPcdOn from "@assets/img/icon/pcd_on.png";
import IconVr2DOff from "@assets/img/icon/vr2D_off.png";
import IconVr2DOn from "@assets/img/icon/vr2D_on.png";
import IconVr3DOff from "@assets/img/icon/vr3D_off.png";
import IconVr3DOn from "@assets/img/icon/vr3D_on.png";
import MainImage from "../../assets/reference/onsuri-main.jpg";
import AccessImage from "../../assets/img/access-image.png";
import ContentLayout from "../../components/ContentLayout";
import MainSearch from "../../components/MyInput/MainSearch";
import { ReactComponent as LeftIcon } from "../../assets/img/icon/left.svg";
import { ReactComponent as RightIcon } from "../../assets/img/icon/right.svg";
import ImageModal from "../../components/Modal/ImageModal";
import { useMutation } from "react-query";
import { requestHeritageDetail, requestHeritageList } from "@apis";
import {
  IHeritageListItem,
  IResponseHeritageAsstesDetail,
} from "@apis/heritage/interface/response";
import { AxiosError } from "axios";
import {
  requestAirLineMarkers,
  requestGeometries,
  requestHighDetailGeoMetries,
  reuqestUnionGeometries,
} from "@apis/geometry";
import {
  getDistance,
  getGeoJSONStrokeColor,
  getImage,
  handlePlainCultureDetailInfomation,
  nullCheck,
} from "utils";
import MapTooltip, { IMapTooltipRef } from "@components/MapTooltip";
import { InfoWindowF, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import GoogleMapTypeController, {
  TStringType,
  stringTypes,
} from "@components/GoogleMapTypeController";
import { debounce } from "lodash";
import CustomGoogleMap from "@components/CustomGoogleMap";
import MapOverlay from "@components/MapOverlay";
import { IAirLineMarkersResponse } from "@apis/geometry/response";

interface Props {}

interface ILatLng {
  lat: number;
  lng: number;
}

const Place: React.FC<Props> = () => {
  const { handleChangePage } = useChangePage();

  /** 구글 맵 */
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCS8FPyUlqkTwh76kcwOoSXrDrb4KB_26I",
    language: "ko",
  });
  const tooltipRef = useRef<IMapTooltipRef>(null);
  const googleStringMapTypeRef = useRef<TStringType>(stringTypes.DEFAULT);

  const [mapLocationInfo] = useState<ILatLng>({
    lat: 37.74385833,
    lng: 126.49,
  });

  const [currentLocation, setCurrentLocation] = useState<ILatLng>({
    lat: 37.74385833,
    lng: 126.49,
  });

  const [currentAllLocation, setCurrentAllLocation] = useState<ILatLng>({
    lat: 37.74385833,
    lng: 126.49,
  });

  /** Google Map Migration */
  const [googleMapZoom, setGoogleMapZoom] = useState<number>(10);
  const googleMapZoomRef = useRef<number>(0);
  // const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const googleMapRef = useRef<google.maps.Map | null>(null);
  const [googleMapType, setGoogleMapType] = useState<string>("terrain");
  const [googleMapStringType, setGoogleStringMapType] = useState<TStringType>(
    stringTypes.DEFAULT
  );

  /** 항공지도 마커 리스트 */
  const [airlineMarkers, setAirlineMarkers] = useState<
    IAirLineMarkersResponse[]
  >([]);
  const onUnmount = React.useCallback(function callback() {
    googleMapRef.current = null;
  }, []);

  /** 구글 맵 End */

  const [nowIndex, setNowIndex] = useState<number>(0);
  const [isDetailInfoOpen, setIsDetailInfoOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [keyword, setKeyword] = useState("");
  const [currentImage, setCurrentImage] = useState<string>(MainImage);
  const [isImageModalVisible, setIsImageModalVisible] =
    useState<boolean>(false);
  const label = useMemo(
    () => ({
      inputProps: { "aria-label": "Checkbox demo" },
      style: { padding: "0" },
    }),
    []
  );

  /** 문화유산 상세 정보 */
  const [cultureDetail, setCultureDetail] =
    useState<IResponseHeritageAsstesDetail | null>(null);
  const [cultureDetailInfo, setCultureDetailInfo] = useState<Label[]>();

  const [data, setData] = useState<IHeritageListItem[]>();

  const { mutate: getHeritageList } = useMutation(requestHeritageList, {
    onSuccess: (data) => {
      const { row } = data;
      const { list } = row;
      setData(list);
      setIsDetailInfoOpen(false);
    },
    onError: (e: AxiosError) => {
      if (e.status === 400) {
        setData([]);
      }
    },
  });

  const { mutate: getHeritageDetail } = useMutation(requestHeritageDetail, {
    onSuccess: (data) => {
      const { row } = data;
      setCultureDetail(row);
      setCurrentImage(row?.images?.[0] || "");
      setCultureDetailInfo(handlePlainCultureDetailInfomation(row));
      setIsDetailInfoOpen(true);
    },
  });

  /** union Geometries */
  const { mutate: getUnionGeometries } = useMutation(reuqestUnionGeometries, {
    onSuccess: (data) => {
      const { features } = data;
      const geoJson = {
        type: "FeatureCollection",
        features,
      };
      googleMapRef.current?.data.revertStyle();
      googleMapRef.current?.data.forEach((item) => {
        if (item.getProperty("geojsonType") === "union") {
          googleMapRef.current?.data?.remove(item);
        }
      });
      if (googleStringMapTypeRef.current === stringTypes.CADASTRAL) {
        googleMapRef.current?.data.addGeoJson(geoJson);
      }
    },
  });

  const { mutate: getHighDetailGeometries } = useMutation(
    requestHighDetailGeoMetries,
    {
      onSuccess: (data) => {
        const { features } = data;
        const geoJson = {
          type: "FeatureCollection",
          features,
        };
        googleMapRef.current?.data.revertStyle();
        googleMapRef.current?.data.forEach((item) => {
          if (item.getProperty("geojsonType") === "high-detail") {
            googleMapRef.current?.data?.remove(item);
          }
        });
        if (googleStringMapTypeRef.current === stringTypes.CADASTRAL) {
          googleMapRef.current?.data.addGeoJson(geoJson);
        }
      },
    }
  );

  /** geometries */
  const { mutate: getGeometries } = useMutation(requestGeometries, {
    onSuccess: (data) => {
      const { features } = data;
      const geoJson = {
        type: "FeatureCollection",
        features,
      };
      googleMapRef.current?.data.revertStyle();
      googleMapRef.current?.data.forEach((item) => {
        if (item.getProperty("geojsonType") === "geometries-all") {
          googleMapRef.current?.data?.remove(item);
        }
      });
      if (googleStringMapTypeRef.current === stringTypes.CADASTRAL) {
        googleMapRef.current?.data.addGeoJson(geoJson);
      }
    },
  });

  /** 항공 지도 마커 */
  const { mutate: getAirLineMarkers } = useMutation(requestAirLineMarkers, {
    onSuccess: (data) => {
      setAirlineMarkers(data.rows);
    },
  });

  const onLoad = (map: google.maps.Map) => {
    map.setCenter(mapLocationInfo);

    map.data.setStyle((feature) => {
      const color = getGeoJSONStrokeColor(feature.getProperty("jimok"));

      if (feature.getProperty("geojsonType") === "geometries-all") {
        return {
          fillColor: "rgba(0,0,0,0)",
          strokeColor: "#000",
          strokeWeight: 2,
          fillOpacity: 0.6,
          // strokeOpacity: 1,
          zIndex: -1,
        };
      } else if (feature.getProperty("geojsonType") === "high-detail") {
        return {
          fillColor: color,
          strokeColor: "#AC8B39",
          strokeWeight: 1,
          fillOpacity: 0.6,
          icon: undefined,
          zIndex: 10,
        };
      } else {
        return {
          fillColor: "#000",
          strokeColor: "#000",
          strokeWeight: 1,
          fillOpacity: 0.6,
          icon: undefined,
          zIndex: 5,
        };
      }
    });

    map.addListener(
      "zoom_changed",
      debounce(() => {
        console.log(map.getZoom(), "<<<<  map.getZoom();");
        const zoom = map.getZoom();
        if (typeof zoom === "number") {
          setGoogleMapZoom(zoom);
          googleMapZoomRef.current = zoom;
        }
      }, 1000)
    );

    map.addListener(
      "center_changed",
      debounce(() => {
        onCenterChanged();
      }, 500)
    );
    map.data.addListener("click", (event: any) => {
      mapAddClickListener(event, map);
    });
    map.data.addListener("mouseover", (event: any) => {
      const { feature } = event;

      if (
        feature.getProperty("geojsonType") === "high-detail" ||
        feature.getProperty("geojsonType") === "geometries-all"
      ) {
        if (!tooltipRef?.current?.visibleFix)
          tooltipRef?.current?.handleOpen(feature);
        googleMapRef.current?.data.overrideStyle(feature, {
          fillOpacity: 1,
        });
      } else {
        googleMapRef.current?.data.overrideStyle(feature, {
          fillOpacity: 6,
        });
      }
    });
    map.data.addListener("mouseout", (event: any) => {
      const { feature } = event;
      if (!tooltipRef?.current?.visibleFix) tooltipRef?.current?.handleClose();
      if (
        tooltipRef?.current?.data?.getProperty("id") !==
          feature.getProperty("id") ||
        !tooltipRef?.current?.visibleFix
      ) {
        if (feature.getProperty("geojsonType") === "high-detail") {
          googleMapRef.current?.data.revertStyle(feature);
        }
      }
    });

    const imageMapType = new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        if (googleStringMapTypeRef.current !== stringTypes.AIRLINE) return "";
        const ymax = 1 << zoom;
        const y = ymax - coord.y - 1;
        return `https://d2hs5sgo9f85cf.cloudfront.net/high-res-tiles/${zoom}/${coord.x}/${y}.png`;
      },
      // tileSize: new google.maps.Size(256, 256),
    });

    map.overlayMapTypes.push(imageMapType);
    googleMapRef.current = map;
  };

  const onCenterChanged = useCallback(() => {
    const center = googleMapRef.current?.getCenter();
    const lat = center?.lat();
    const lng = center?.lng();

    if (lat && lng) {
      setCurrentAllLocation((prev) => {
        if (prev) {
          const dist = getDistance(prev.lat, prev.lng, lat, lng);
          if (googleMapZoomRef.current) {
            if (googleMapZoomRef.current < 8 && dist < 400000) {
              return prev;
            } else if (googleMapZoomRef.current < 10 && dist < 1200000) {
              return prev;
            } else if (dist < 4000) {
              return prev;
            } else {
              return { lat, lng };
            }
          } else {
            return { lat, lng };
          }
        }
        return { lat, lng };
      });

      setCurrentLocation((prev) => {
        if (prev) {
          const dist = getDistance(prev.lat, prev.lng, lat, lng);
          if (dist < 4000) {
            return prev;
          } else {
            return { lat, lng };
          }
        } else {
          return { lat, lng };
        }
      });
    }
  }, []);

  const mapAddClickListener = useCallback(
    (event: any, map: google.maps.Map) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const { feature } = event;
      if (
        feature.getProperty("geojsonType") === "high-detail" ||
        feature.getProperty("geojsonType") === "geometries-all"
      ) {
        // setFeatureState((prev: any) => {
        if (tooltipRef?.current?.visibleFix) {
          if (
            tooltipRef?.current?.data?.getProperty("id") ===
            feature.getProperty("id")
          ) {
            tooltipRef?.current?.handleVisibleFix();
            tooltipRef?.current?.handleClose();
          } else {
            map?.data.revertStyle();
            map?.data.overrideStyle(feature, {
              fillOpacity: 1,
            });
            tooltipRef?.current?.handleOpen(feature);
          }
        } else {
          map?.data.overrideStyle(feature, {
            fillOpacity: 1,
          });
          tooltipRef?.current?.handleOpen(feature);
          if (!tooltipRef?.current?.visibleFix) {
            tooltipRef?.current?.handleVisibleFix();
          }
        }
      }

      if (feature.getProperty("geojsonType") !== "geometries-all") {
        map?.setCenter({ lat, lng });

        if (lat && lng) {
          onCenterChanged();
        }
        if ((map?.getZoom() || 0) < 15) {
          map?.setZoom(15);
        }
      }
    },
    [onCenterChanged]
  );

  const handleMarkerClick = (data: IHeritageListItem, index: number) => {
    getHeritageDetail({ id: data.id });
  };

  const handleAirLineMarkerClick = (item: IAirLineMarkersResponse) => {
    getHeritageDetail({ id: item.iriId });
    googleMapRef.current?.setCenter({
      lat: Number(item.lat),
      lng: Number(item.lng),
    });
    googleMapRef.current?.setZoom(18);
  };

  const handleChangeGoogleMapType = (
    type: google.maps.MapTypeId,
    stringMapType: string
  ) => {
    setGoogleMapType(type);
    setGoogleStringMapType(stringMapType);
    googleStringMapTypeRef.current = stringMapType;
  };

  const handleChangeImage = (url: string, idx: number) => {
    return () => {
      setCurrentImage(url);
      setNowIndex(idx);
    };
  };
  const showNewBrowser = (id: string) => {
    window.open(`${window.location.origin}/heritage/${encodeURIComponent(id)}`);
  };
  const handleReset = () => {
    setSelectedCategory([]);
  };

  const handleDetailInfoOpenClick = () => {
    setIsDetailInfoOpen((prev) => !prev);
  };

  const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleChangeCategory = (category: string) => {
    if (selectedCategory.includes(category)) {
      setSelectedCategory((prev) =>
        prev.filter((prevValue) => prevValue !== category)
      );
    } else {
      setSelectedCategory((prev) => prev.concat(category));
    }
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // handleDataChange(keyword);
    getHeritageList({
      subject: keyword,
      page: 1,
      pageSize: 1000,
      types: selectedCategory,
    });
  };

  // 카테고리 선택 시
  useEffect(() => {
    getHeritageList({
      subject: keyword,
      page: 1,
      pageSize: 1000,
      types: selectedCategory,
    });
  }, [selectedCategory]);

  useEffect(() => {
    if (googleMapStringType === stringTypes.CADASTRAL) {
      if (googleMapZoom >= 13) {
        getGeometries({
          distance: 4000,
          lat: currentAllLocation?.lat,
          lng: currentAllLocation?.lng,
          type: "myeon",
        });
      } else if (googleMapZoom >= 9) {
        getGeometries({
          distance: 400000,
          lat: currentAllLocation?.lat,
          lng: currentAllLocation?.lng,
          type: "gun",
        });
      } else {
        getGeometries({
          distance: 1200000,
          lat: currentAllLocation?.lat,
          lng: currentAllLocation?.lng,
          type: "do",
        });
      }
    } else {
    }
  }, [googleMapStringType, getGeometries, currentAllLocation, googleMapZoom]);

  useEffect(() => {
    if (googleMapStringType === stringTypes.CADASTRAL) {
      if (googleMapZoom < 13) {
        getUnionGeometries();
        googleMapRef.current?.data.forEach((item) => {
          if (item.getProperty("geojsonType") === "high-detail") {
            googleMapRef.current?.data.remove(item);
          }
        });
      }
      if (googleMapZoom >= 13) {
        getHighDetailGeometries({
          distance: 4000,
          lat: currentLocation?.lat,
          lng: currentLocation?.lng,
        });
        googleMapRef.current?.data.forEach((item) => {
          if (item.getProperty("geojsonType") === "union") {
            googleMapRef.current?.data.remove(item);
          }
        });
      }
    } else {
    }
  }, [
    googleMapStringType,
    getUnionGeometries,
    googleMapZoom,
    getHighDetailGeometries,
    currentLocation,
  ]);

  const markersVisiblesRef = useRef<boolean[]>([]);
  const [_, setMarkersVisible] = useState<boolean>(false);

  useEffect(() => {
    if (googleMapStringType !== stringTypes.CADASTRAL) {
      googleMapRef.current?.data.forEach((feature) => {
        googleMapRef.current?.data.remove(feature);
      });
    }
    if (googleMapStringType === stringTypes.AIRLINE) {
      getAirLineMarkers();
    } else {
      setAirlineMarkers([]);
    }
  }, [getAirLineMarkers, googleMapStringType]);

  return (
    <Layout selectedMenu="place" onChangePage={handleChangePage}>
      <PageWrap>
        <PlacePageWrap>
          <PlacePageTitle>
            <h2>공간 정보</h2>
            <div>네트워크 정보 비교를 진행할 자료를 검색할 수 있습니다.</div>
          </PlacePageTitle>
          <ContentLayout padding={22}>
            <form onSubmit={handleSubmit}>
              <PlaceSearchWrap>
                <MainSearch
                  height="48px"
                  placeholder="검색어를 입력해 주세요"
                  value={keyword}
                  onChange={handleKeywordChange}
                />
                <MyButton type="submit" height="48px" width="58px">
                  검색
                </MyButton>
              </PlaceSearchWrap>
            </form>
          </ContentLayout>
          <SelectArea>
            <ContentLayout>
              <SelectWrap>
                <ContentLabel>공간 유형 선택</ContentLabel>
                <CheckBoxList>
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("문화유산")}
                      onChange={() => handleChangeCategory("문화유산")}
                    />
                    <span>문화유산</span>
                  </CheckBoxWrap>
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("집단기억")}
                      onChange={() => handleChangeCategory("집단기억")}
                    />
                    <span>집단기억</span>
                  </CheckBoxWrap>
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("지명")}
                      onChange={() => handleChangeCategory("지명")}
                    />
                    <span>지명</span>
                  </CheckBoxWrap>
                  {/**  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("지번")}
                      onChange={() => handleChangeCategory("지번")}
                    />
                    
                    <span>지번</span>
                  </CheckBoxWrap>
                   */}
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("인물")}
                      onChange={() => handleChangeCategory("인물")}
                    />
                    <span>인물</span>
                  </CheckBoxWrap>
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("기관")}
                      onChange={() => handleChangeCategory("기관")}
                    />
                    <span>기관</span>
                  </CheckBoxWrap>
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("단체")}
                      onChange={() => handleChangeCategory("단체")}
                    />
                    <span>단체</span>
                  </CheckBoxWrap>
                  <CheckBoxWrap>
                    <Checkbox
                      {...label}
                      checked={selectedCategory.includes("사건")}
                      onChange={() => handleChangeCategory("사건")}
                    />
                    <span>사건</span>
                  </CheckBoxWrap>
                </CheckBoxList>
                <ResetButton>
                  <ResetIcon onClick={handleReset} />
                </ResetButton>
              </SelectWrap>
            </ContentLayout>
          </SelectArea>

          <ImageModal
            param={Number(cultureDetail?.id)}
            visible={isImageModalVisible}
            onClose={() => {
              setIsImageModalVisible(false);
            }}
            images={cultureDetail?.images || []}
            nowIndex={nowIndex}
          />

          <PlaceMapWrap>
            <MapWrap>
              <GoogleMapTypeController
                currentType={googleMapStringType}
                onChange={handleChangeGoogleMapType}
              />
              <CustomGoogleMap
                isLoaded={isLoaded}
                mapContainerStyle={{
                  width: isDetailInfoOpen ? 1120 - 450 : 1120,
                  height: 630,
                }}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  minZoom: 8,
                  restriction: {
                    latLngBounds: {
                      north: 38.634,
                      south: 33.0041,
                      east: 131.5775,
                      west: 124.2656,
                    },
                  },
                }}
                mapTypeId={googleMapType}
                onLoad={onLoad}
                onUnmount={onUnmount}
                zoom={10}
              >
                {data?.map((item, idx) => {
                  if (item.lat && item.lng) {
                    return (
                      <MarkerF
                        onClick={() => handleMarkerClick(item, idx)}
                        onMouseOver={() => {
                          markersVisiblesRef.current[idx] = true;
                          setMarkersVisible((prev) => !prev);
                        }}
                        onMouseOut={() => {
                          markersVisiblesRef.current[idx] = false;
                          setMarkersVisible((prev) => !prev);
                        }}
                        position={{
                          lat: Number(item.lat),
                          lng: Number(item.lng),
                        }}
                        key={item.id}
                        onLoad={(marker) => {
                          markersVisiblesRef.current.push(false);
                        }}
                      >
                        {markersVisiblesRef.current[idx] && (
                          <InfoWindowF>
                            <MapOverlay info={item} />
                          </InfoWindowF>
                        )}
                      </MarkerF>
                    );
                  }
                  return null;
                })}
                {airlineMarkers?.map((item, idx) => (
                  <MarkerF
                    key={idx.toString()}
                    icon={{
                      url: "/img/icon/AerialMap.svg",
                      scaledSize: new window.google.maps.Size(32, 32),
                    }}
                    onClick={() => handleAirLineMarkerClick(item)}
                    onMouseOver={() => {
                      markersVisiblesRef.current[idx] = true;
                      setMarkersVisible((prev) => !prev);
                    }}
                    onMouseOut={() => {
                      markersVisiblesRef.current[idx] = false;
                      setMarkersVisible((prev) => !prev);
                    }}
                    position={{
                      lat: Number(item.lat),
                      lng: Number(item.lng),
                    }}
                    onLoad={(marker) => {
                      markersVisiblesRef.current.push(false);
                    }}
                  >
                    {markersVisiblesRef.current[idx] && (
                      <InfoWindowF>
                        <div style={{ fontSize: "14px" }} className="overlay">
                          <div>이름 : {nullCheck(item.label)}</div>
                          {/* <div>구분 : {nullCheck(info.typeName)}</div>
      <div>분류 : {nullCheck(info.classificationTypeName)}</div>
      <div>소재지 : {nullCheck(info.place)}</div> */}
                        </div>
                      </InfoWindowF>
                    )}
                  </MarkerF>
                ))}
              </CustomGoogleMap>
              <MapTooltip ref={tooltipRef} />
              <ExpandButton onClick={handleDetailInfoOpenClick}>
                {isDetailInfoOpen ? <RightIcon /> : <LeftIcon />}
              </ExpandButton>
            </MapWrap>
            <PlaceDetailInfoWrap isDetailInfoOpen={isDetailInfoOpen}>
              {cultureDetail ? (
                <HeritageImageListWrap>
                  <HeritageInfoTitle>문화유산 정보</HeritageInfoTitle>
                  <HeritageImagesWrap>
                    <SelectedImageWrap
                      onClick={() => {
                        if (cultureDetail?.images?.length > 0)
                          setIsImageModalVisible(true);
                      }}
                    >
                      <img
                        src={
                          currentImage ||
                          getImage(
                            cultureDetail?.protoTypeName ||
                              cultureDetail?.typeWithoutUri
                          )
                        }
                        alt={"slide-main-img"}
                      />
                    </SelectedImageWrap>
                    <ImageSlideWrap>
                      <ImageSlide slideWidth={408} imageWidth={68}>
                        {cultureDetail?.images?.map((item, idx) => (
                          <ImageWrap key={idx.toString()}>
                            <SlideImage
                              onClick={handleChangeImage(item, idx)}
                              src={item}
                              height="100%"
                            />
                          </ImageWrap>
                        ))}
                      </ImageSlide>
                    </ImageSlideWrap>
                    <ActionButtonWrap>
                      <ActionButtonList>
                        {cultureDetail?.vr2D ? (
                          <LinkIcon
                            // href={"https://onsurianglicanchurch.netlify.app"}
                            href={cultureDetail?.vr2D}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              width={"100%"}
                              alt="vr2d-on"
                              src={IconVr2DOn}
                            />
                          </LinkIcon>
                        ) : (
                          <LinkIcon>
                            <img
                              width={"100%"}
                              alt="vr2d-off"
                              src={IconVr2DOff}
                            />
                          </LinkIcon>
                        )}
                        {cultureDetail?.vr3D ? (
                          <LinkIcon
                            // href={"https://onsurianglicanchurch.netlify.app"}
                            href={cultureDetail?.vr3D}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              width={"100%"}
                              alt="vr3d-on"
                              src={IconVr3DOn}
                            />
                          </LinkIcon>
                        ) : (
                          <LinkIcon>
                            <img
                              width={"100%"}
                              alt="vr3d-off"
                              src={IconVr3DOff}
                            />
                          </LinkIcon>
                        )}
                        {cultureDetail?.model3D ? (
                          <LinkIcon
                            href={cultureDetail?.model3D}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              width={"100%"}
                              alt="model3d-on"
                              src={IconModel3DOn}
                            />
                          </LinkIcon>
                        ) : (
                          <LinkIcon>
                            <img
                              width={"100%"}
                              alt="model3d-off"
                              src={IconModel3DOff}
                            />
                          </LinkIcon>
                        )}
                        {/*{cultureDetail?.cad ? (*/}
                        {/*  <LinkIcon*/}
                        {/*    href={cultureDetail?.cad}*/}
                        {/*    target="_blank"*/}
                        {/*    rel="noopener noreferrer"*/}
                        {/*  >*/}
                        {/*    <img width={"100%"} alt="cad-on" src={IconCadOn} />*/}
                        {/*  </LinkIcon>*/}
                        {/*) : (*/}
                        {/*  <LinkIcon>*/}
                        {/*    <img*/}
                        {/*      width={"100%"}*/}
                        {/*      alt="cad-off"*/}
                        {/*      src={IconCadOff}*/}
                        {/*    />*/}
                        {/*  </LinkIcon>*/}
                        {/*)}*/}
                        {cultureDetail?.imageHiresUrl ? (
                          <LinkIcon
                            href={cultureDetail?.imageHiresUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              width={"100%"}
                              alt="hires-on"
                              src={IconImageHiresOn}
                            />
                          </LinkIcon>
                        ) : (
                          <LinkIcon>
                            <img
                              width={"100%"}
                              alt="hires-off"
                              src={IconImageHiresOff}
                            />
                          </LinkIcon>
                        )}
                        {cultureDetail?.pcd ? (
                          <LinkIcon
                            href={cultureDetail?.pcd}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img width={"100%"} alt="pcd-on" src={IconPcdOn} />
                          </LinkIcon>
                        ) : (
                          <LinkIcon>
                            <img
                              width={"100%"}
                              alt="pcd-off"
                              src={IconPcdOff}
                            />
                          </LinkIcon>
                        )}
                      </ActionButtonList>
                      <div>
                        <img
                          src={AccessImage}
                          alt="접근성"
                          width={165}
                          height={52}
                        />
                      </div>
                    </ActionButtonWrap>
                  </HeritageImagesWrap>
                  <HeritageImageInfoWrap>
                    {cultureDetailInfo && (
                      <InfoLabelGroup items={cultureDetailInfo} />
                    )}
                    <ShowDetailButtonWrap>
                      <MyButton
                        width="140px"
                        onClick={() => showNewBrowser(cultureDetail?.id)}
                      >
                        더 알아보기
                      </MyButton>
                    </ShowDetailButtonWrap>
                  </HeritageImageInfoWrap>
                </HeritageImageListWrap>
              ) : (
                <EmptyWrap>선택된 정보가 없습니다.</EmptyWrap>
              )}
            </PlaceDetailInfoWrap>
          </PlaceMapWrap>
        </PlacePageWrap>
      </PageWrap>
    </Layout>
  );
};
export default Place;

const PlacePageWrap = styled.div`
  padding-top: 42px;
`;
const PlacePageTitle = styled.div`
  ${fonts("B3")};
  h2 {
    ${fonts("H3")};
    margin-bottom: 12px;
  }
  margin-bottom: 32px;
`;
const PlaceSearchWrap = styled.div`
  display: flex;
  gap: 0 16px;
`;
const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`;
const ResetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border: 1px solid ${colors.GRAY6};
  border-radius: 4px;
  cursor: pointer;
`;
const PlaceMapWrap = styled.div`
  display: flex;
  margin-top: 40px;
  border-radius: 16px;
  height: 630px;
  overflow: hidden;
`;
const HeritageInfoTitle = styled.h3`
  ${fonts("H5")};
  margin-bottom: 10px;
`;
const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 77px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 20;
  background-color: ${colors.WHITE};
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid ${colors.GRAY5};
`;
const MapWrap = styled.div`
  flex: 1 1 665px;
  position: relative;

  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
`;
const Map = styled.div<{ isDetailInfoOpen: boolean }>`
  width: 100%;
  height: 100%;
`;
const EmptyWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fonts("H5")};
  color: ${colors.GRAY7};
`;
const PlaceDetailInfoWrap = styled.div<{ isDetailInfoOpen: boolean }>`
  width: ${({ isDetailInfoOpen }) => (isDetailInfoOpen ? "455px" : "0px")};
  height: 100%;
`;
const HeritageImageListWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${colors.WHITE};
`;
const HeritageImagesWrap = styled.div`
  flex: 1;
`;
const ImageSlideWrap = styled.div`
  margin-top: 20px;
`;
const HeritageImageInfoWrap = styled.div`
  margin-top: 16px;
  flex: 1;
`;

const SelectedImageWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 291px;
  border-radius: 16px;
  overflow: hidden;
`;
const ActionButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const ActionButtonList = styled.div`
  display: flex;
  gap: 0 8px;
`;
const ImageWrap = styled.div`
  position: relative;
  width: 68px;
  height: 68px;
  overflow: hidden;
  cursor: pointer;
`;
const LinkIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const SelectArea = styled.div`
  margin-top: 32px;
`;
const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`;
const ContentLabel = styled.div`
  ${fonts("H6")};
`;
const CheckBoxList = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0 16px;
`;
const SlideImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ShowDetailButtonWrap = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const TooltipUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 12px;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
`;
