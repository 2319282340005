import React from "react";
import styled from "styled-components";
import { routePath } from "../../constants";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import MyButton from "../Button";
import { IHeritageRelatedItem } from "@apis/heritage/interface/response";
import Agency from "../../assets/img/heritage/agency.png";
import Character from "../../assets/img/heritage/character.png";
import Event from "../../assets/img/heritage/event.png";
import Heritage from "../../assets/img/heritage/heritage.png";
import Individual from "../../assets/img/heritage/individual.png";
import Location from "../../assets/img/heritage/location.png";
import Organ from "../../assets/img/heritage/organization.png";

export interface ReferenceData {
  data?: IHeritageRelatedItem;
}

const SlideHeritageCard: React.FC<ReferenceData> = ({ data }) => {
  const handleOpenClick = () => {
    window.open(`${routePath.HERITAGE}/${encodeURIComponent(data?.id || "")}`);
  };

  const getImage = (type?: string) => {
    switch (type) {
      case "인물":
        return Character;
      case "단체":
        return Organ;
      case "기관":
        return Agency;
      case "개체":
        return Individual;
      case "문화유산":
        return Heritage;
      case "장소":
        return Location;
      case "사건":
        return Event;
      default:
        return Individual;
    }
  };

  return (
    <InfoHeritageWrap>
      <InfoHeritageimageWrap>
        <InfoHeritageImage
          src={data?.image || getImage(data?.protoTypeName)}
          alt="문화유산 이미지"
        />
      </InfoHeritageimageWrap>
      <InfoHeritageName>{data?.label ?? data?.withoutUriId}</InfoHeritageName>
      <div style={{ marginBottom: 16 }}>
        <InfoHeritageType>
          <div>구분</div>
          <div>{data?.protoTypeName ?? "-"}</div>
        </InfoHeritageType>
        <InfoHeritage>
          <div>지정분류</div>
          <div>{data?.classificationTypeName ?? "-"}</div>
        </InfoHeritage>
        <InfoHeritage>
          <div>장소</div>
          <div>{data?.place ?? "-"}</div>
        </InfoHeritage>
      </div>

      <MyButton onClick={handleOpenClick}>더 알아보기</MyButton>
    </InfoHeritageWrap>
  );
};
export default SlideHeritageCard;

const InfoHeritageWrap = styled.div`
  width: 274px;
  padding: 24px 20px;
  background-color: ${colors.GRAY11};
  border-radius: 8px;
  margin-bottom: 20px;
  &:hover {
    background-color: ${colors.GRAY10};
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.08); inset;

    & > div:nth-child(1) > img {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;
const InfoHeritageimageWrap = styled.div`
  position: relative;
  overflow: hidden;
  width: 234px;
  height: 162px;
`;
const InfoHeritageImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  border-radius: 4px;
  transition: 0.1s linear;
`;
const InfoHeritageName = styled.div`
  margin-top: 16px;
  ${fonts("H6")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const InfoHeritageInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  ${fonts("B5")};
  > div:nth-child(1) {
    color: ${colors.GRAY5};
  }
`;
const InfoHeritage = styled(InfoHeritageInfoItem)`
  margin-top: 4px;
`;
const InfoHeritageType = styled(InfoHeritageInfoItem)`
  margin-top: 12px;

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 0 4px;
  }
`;
