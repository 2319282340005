import _ from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { regionKey } from "utils";

export interface IMapTooltipRef {
  handleOpen: (data: any) => void;
  handleVisibleFix: () => void;
  visibleFix: boolean;
  data: any;
  handleClose: () => void;
}

const MapTooltip = forwardRef<IMapTooltipRef, any>((props, ref) => {
  const [visibleFix, setVisibleFix] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any>(null);
  const [region, setRegion] = useState<any>(null);

  useImperativeHandle(ref, () => ({
    handleOpen: (data: any) => {
      console.log("이거 타니 ?");
      setVisible(true);
      setData(data);
    },
    handleVisibleFix: () => {
      setVisibleFix((prev) => !prev);
    },
    visibleFix,
    data,
    handleClose: () => {
      if (!visibleFix) {
        setVisible(false);
        setData(null);
      }
    },
  }));

  useEffect(() => {
    if (data) {
      setRegion({
        jibun_nm: data.getProperty("jibun_nm") || null,
        jimok: data.getProperty("jimok") || null,
        "1910jimok": data.getProperty("1910jimok") || null,
        "1910area_P": data.getProperty("1910area_P") || null,
        "1910sin_y1": data.getProperty("1910sin_y1") || null,
        "1910sin_y2": data.getProperty("1910sin_y2") || null,
        "1910sin_md": data.getProperty("1910sin_md") || null,
        "1910ownAd": data.getProperty("1910ownAd") || null,
        "1910ownPn": data.getProperty("1910ownPn") || null,
        "1910ownPn2": data.getProperty("1910ownPn2") || null,
        "1910ownP": data.getProperty("1910ownP") || null,
        "1910ownTy": data.getProperty("1910ownTy") || null,
        "1910resdTy": data.getProperty("1910resdTy") || null,
        regionNameKo: data.getProperty("regionNameKo") || null,
      });
    }
  }, [data]);

  if (!visible) return null;

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1000,
        padding: "5px",
        backgroundColor: "#fff",
        border: "solid 1px #cdcdcd",
        borderRadius: "12px",
        fontSize: "14px",
        pointerEvents: "none",
        display: "block",
        right: 0,
        bottom: 0,
      }}
    >
      <TooltipUl>
        {_.map(region, (item, key) => {
          if (item)
            return (
              <li key={key.toString()}>
                <span>
                  {regionKey[key as keyof typeof regionKey] &&
                    `${regionKey[key as keyof typeof regionKey]} : `}
                </span>
                {item}
              </li>
            );
        })}
      </TooltipUl>
    </div>
  );
});

export default MapTooltip;

const TooltipUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 12px;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
`;
