import React, { ReactNode } from "react";
import styled from "styled-components";
import Portal from "./Portal";
import { ReactComponent as CloseIcon } from "../../assets/img/icon/Close.svg";
import { colors } from "../../theme/colors";

interface Props {
  children: ReactNode;
  onClick: () => void;
  visible: boolean;
}

const Modal: React.FC<Props> = ({ visible, children, onClick }) => {
  return (
    <Portal visible={visible}>
      <ModalContainer>
        <ModalLayout>
          <ModalHeader>
            <ModalCloseButton onClick={onClick} />
          </ModalHeader>
          <div>{children}</div>
        </ModalLayout>
      </ModalContainer>
    </Portal>
  );
};
export default Modal;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalLayout = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 16px;
  overflow: hidden;
`;
const ModalCloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

const ModalHeader = styled.div`
  padding: 24px;
  text-align: right;
`;
