import { Rows } from "@apis/index-common";
import {
  IRequestHighDetailGeoMetries,
  IRequestrequestGeometries,
} from "./requeest";
import {
  IAirLineMarkersResponse,
  ICustomGemetries,
  ICustomGemetriesFeatures,
  ICustomHighDetailFeatures,
  ICustomResponseGeometries,
  ICustomResponseHighDetailGeometries,
  ICustomResponseUnionFeatures,
  ICustomUnionFeatures,
  IResponseHighDetailGeometries,
  IResponseUnionGeometries,
} from "./response";
import httpRequest from "@networks/index";

const ApiUrls = {
  highDetailGeoMetries: "/high-detail-geometries",
  unionGeometries: "/union-geometries",
  geometries: "/geometries",
  highResolutionTilePoints: "/high-resolution-tile-points",
};

/** 지도 리스트 조회 */
export const requestHighDetailGeoMetries = async (
  params: IRequestHighDetailGeoMetries
): Promise<ICustomResponseHighDetailGeometries> => {
  const { data } = await httpRequest<Rows<IResponseHighDetailGeometries>>(
    "GET",
    ApiUrls.highDetailGeoMetries,
    params
  );
  const { rows } = data;
  const features: ICustomHighDetailFeatures[] =
    rows?.map((item) => {
      return {
        type: "Feature",
        geometry: item.geoPolygon,
        properties: {
          id: item.id,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          deletedAt: item.deletedAt,
          jimok: item.jimok,
          note_od: item.note_od,
          jibun_nm: item.jibun_nm,
          "1910jimok": item["1910jimok"],
          "1910area_P": item["1910area_P"],
          "1910sin_y1": item["1910sin_y1"],
          "1910sin_y2": item["1910sin_y2"],
          "1910sin_md": item["1910sin_md"],
          "1910ownAd": item["1910ownAd"],
          "1910ownPn": item["1910ownPn"],
          "1910ownPn2": item["1910ownPn2"],
          "1910ownP": item["1910ownP"],
          "1910ownTy": item["1910ownTy"],
          "1910resdTy": item["1910resdTy"],
          geojsonType: "high-detail",
        },
      };
    }) || [];

  return {
    count: data.count || 0,
    features: features || [],
  };
};

/** 지도 GeoJSON 전체 조회 */
export const reuqestUnionGeometries =
  async (): Promise<ICustomResponseUnionFeatures> => {
    const { data } = await httpRequest<Rows<IResponseUnionGeometries>>(
      "GET",
      ApiUrls.unionGeometries
    );
    const { rows } = data;
    const features: ICustomUnionFeatures[] =
      rows?.map((item) => {
        return {
          type: "Feature",
          geometry: item.geoPolygon,
          properties: {
            geojsonType: "union",
          },
        };
      }) || [];
    return {
      count: data.count || 0,
      features,
    };
  };

/** 지도 GeoJSON 도 군 면 */
export const requestGeometries = async (
  params: IRequestrequestGeometries
): Promise<ICustomGemetries> => {
  const { data } = await httpRequest<Rows<ICustomResponseGeometries>>(
    "GET",
    ApiUrls.geometries,
    params
  );
  const { rows } = data;
  const features: ICustomGemetriesFeatures[] =
    rows?.map((item) => {
      return {
        type: "Feature",
        geometry: item.geoPolygon,
        properties: {
          id: item.id,
          isDiff: true,
          regionName: item.regionName,
          regionNameKo: item.regionNameKo,
          type: item.type,
          geojsonType: "geometries-all",
        },
      };
    }) || [];
  return {
    features,
  };
};

/** 항공 지도 마커 */
export const requestAirLineMarkers = async (): Promise<
  Rows<IAirLineMarkersResponse>
> => {
  const { data } = await httpRequest<Rows<IAirLineMarkersResponse>>(
    "GET",
    ApiUrls.highResolutionTilePoints
  );

  return data;
};
