import { IResponseHeritageWordCloudLists } from "@apis/heritage/interface/response";
import {
  ICultureDetailInfoItem,
  ICultureLabelRecord,
} from "@pages/Heritage/Id/types";
import _ from "lodash";
import Agency from "../assets/img/heritage/agency.png";
import Character from "../assets/img/heritage/character.png";
import Event from "../assets/img/heritage/event.png";
import Heritage from "../assets/img/heritage/heritage.png";
import Individual from "../assets/img/heritage/individual.png";
import Location from "../assets/img/heritage/location.png";
import Organ from "../assets/img/heritage/organization.png";

export const nullCheck = (value: any, returnValue?: (data: any) => any) => {
  if (value !== undefined && value !== null) {
    if (returnValue) {
      return returnValue(value);
    }
    return value;
  }
  return "-";
};

export const regionKey = {
  jimok: "지목(한글)",
  jibun_nm: "지번",
  "1910jimok": "지목(한자)",
  "1910area_P": "면적(평)",
  "1910sin_y1": "신고년(일본연호)",
  "1910sin_y2": "신고년",
  "1910sin_md": "신고일자",
  "1910ownAd": "소유자 주소",
  "1910ownPn": "소유자(원문)",
  "1910ownPn2": "소유자(한글)",
  "1910ownP": "기타",
  "1910ownTy": "토지 소유",
  "1910resdTy": "소유자 거주지",
  regionNameKo: "지역명",
};

const obj: ICultureLabelRecord = {
  label: "문화유산명",
  typeName: "분류",
  classificationTypeName: "지정분류",
  designatedDate: "지정(등록)일",
  address: "소재지",
  manager: "소유자(소유단체)",
  withoutUriId: "문화유산명",
  isBornAt: "출생지",
  isDeadAt: "사망지",
  residesIn: "거주지",
  roadNmAddress: "주소",
  isLocatedIn: "위치",
  currentLocation: "현 소재지",
  happenedAt: "발생지",
  history: "연혁",
  transliteration: "음역",
  sex: "성별",
  country: "국적/시대",
  origin: "본관",
  socialStatus: "신분/직위",
  ja: "자(字)",
  ho: "호(號)",
  bongho: "봉호(封號)",
  siho: "시호(諡號)",
  hasFamilyName: "가문",
  isBornIn: "생년",
  isDeadIn: "몰년",
  ParticipatedIn: "참여",
  identifier: "지정번호",
  designatedData: "지정일",
  created: "제작/건립/설립시기",
  creator: "제작자/건립자/설립자",
  isOwnedBy: "소유자(소유단체)",
  isManagedBy: "관리자(관리단체)",
};

export const handlePlainCultureDetailInfomation = (
  cultureData: ICultureLabelRecord
) => {
  const cultureInfo: ICultureDetailInfoItem[] = Object.entries(obj).map(
    ([key, value]) => {
      let setValue;
      if (
        (key === "label" || key === "withoutUriId") &&
        cultureData[key as keyof ICultureLabelRecord] &&
        cultureData["cnName"]
      ) {
        setValue = `${cultureData[key as keyof ICultureLabelRecord]}(${
          cultureData["cnName"]
        })`;
      } else {
        setValue = cultureData[key as keyof ICultureLabelRecord] || null;
      }
      return {
        label: value, // 라벨이 없는 경우를 대비하여 기본값을 설정합니다.
        value: setValue,
      };
    }
  );

  return uniqueCheckArray(cultureInfo, "label");
};

export const handlePlainCultureWordCloud = (
  wordCloud: IResponseHeritageWordCloudLists[]
) => {
  const wordCloudData = wordCloud?.map((item) => ({
    text: item.label || item.withoutUriId,
    value: item.count,
    id: item.id,
  }));

  return wordCloudData;
};

/** 위도 경도 계산기 */
export const getDistance = (
  prevLat: number,
  prevLng: number,
  lat: number,
  lng: number
) => {
  const radLat1 = (Math.PI * prevLat) / 180;
  const radLat2 = (Math.PI * lat) / 180;
  const theta = prevLng - lng;
  const radTheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  if (dist > 1) dist = 1;

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515 * 1.609344 * 1000;

  return dist;
};

/** GeoJSON get Color */
export const getGeoJSONStrokeColor = (jimok: string) => {
  switch (jimok) {
    case "구":
      return "#4de1ff";
    case "답":
      return "#d8fcec";
    case "대":
      return "#ffda4c";
    case "도":
      return "#a1a1a1";
    case "묘":
      return "#e6d49e";
    case "임":
      return "#4cbaab";
    case "잡":
      return "#ea8a82";
    case "천":
      return "#4dbdff";
    case "전":
      return "#d8fcec";
    case "제":
      return "#a1a1a1";
    case "지":
      return "#4de1ff";

    default:
      return "#e6d49e";
  }
};
// if(cultureData?.label) {
//   cultureInfo.push({
//     label: "문화유산명",
//     value: cultureData?.label,
//   })
// }
// if(cultureData?.designatedDate) {
//   cultureInfo?.push({
//     label: "지정(등록)일",
//     value: cultureData?.designatedDate
//   })
// }
// if(cultureData?.)

// cultureDetail?.label && {
//   label: "문화유산명",
//   value: cultureDetail?.label,
// },
// // {
// //   label: "구분",
// //   value: "문화유산 > 유적건조물 > 종교신앙",
// // },
// // {
// //   label: "면적",
// //   value: (
// //     <div>
// //       618m<sup>2</sup>
// //     </div>
// //   ),
// // },
// // {
// //   label: "지정번호",
// //   value: "인천 유형문화재 제52호",
// // },
// {
//   label: "지정(등록)일",
//   value: cultureDetail?.designatedDate,
// },
// {
//   label: "건립시기",
//   value: cultureDetail?.created,
// },
// {
//   label: "소재지",
//   value: cultureDetail?.address,
// },
// {
//   label: "소유자(소유단체)",
//   value: cultureDetail?.manager,
// },
// {
//   label: "관리자(관리단체)",
//   value: cultureDetail?.manager,
// },
// // {
// //   label: "연혁",
// //   value: (
// //     <div>
// //       1906년 완공
// //       <br />
// //       1906년 축성
// //       <br />
// //       2003년 인천 유형문화재 제52호 지정
// //       <br />
// //       2004년 보수공사
// //       <br />
// //       2007년 주변정비공사
// //     </div>
// //   ),
// // },
// ]);

// 중복 제거
export const uniqueCheckArray = (data: any[], key: string, key2?: string) => {
  return [..._.cloneDeep(data)].filter((item, idx, arr) => {
    if (!key2)
      return arr.findIndex((i) => i[`${key}`] === item[`${key}`]) === idx;
    return (
      arr.findIndex(
        (i) =>
          i[`${key}`] === item[`${key}`] && i[`${key2}`] === item[`${key2}`]
      ) === idx
    );
  });
};

export const getImage = (type?: string) => {
  switch (type) {
    case "인물":
      return Character;
    case "단체":
      return Organ;
    case "기관":
      return Agency;
    case "개체":
      return Individual;
    case "문화유산":
      return Heritage;
    case "장소":
      return Location;
    case "사건":
      return Event;
    default:
      return Individual;
  }
};
