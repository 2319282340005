import React from "react";
import styled from "styled-components";
import video from "../../assets/video/intro.mp4";
import { ReactComponent as Logo } from "../../assets/img/icon/logo_w.svg";
import { fonts } from "../../theme/fonts";
import { colors } from "../../theme/colors";

interface Props {}

const IntroPage: React.FC<Props> = () => {
  return (
    <Background>
      {/* <Image1 /> */}
      <VideoWrap>
        <Video autoPlay muted loop>
          <source src={video} type="video/mp4"></source>
        </Video>
      </VideoWrap>
      <IntroTitleWrap>
        <div>
          <Logo width={80} height={80} />
        </div>
        <IntroTitle>Korean Cultural Heritage Resources</IntroTitle>
        <IntroInfo>지역역사 문화자산 AI 아카이브 플랫폼</IntroInfo>
      </IntroTitleWrap>
    </Background>
  );
};
export default IntroPage;

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;
const IntroTitleWrap = styled.div`
  width: 930px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  /* opacity: 0; */
  z-index: 10;
  color: black;
`;
const IntroTitle = styled.h2`
  margin-top: 21px;
  display: inline-block;
  ${fonts("D2")};
  color: ${colors.WHITE};
`;
const IntroInfo = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: ${colors.WHITE};
`;
const VideoWrap = styled.div`
  width: 100vw;
  height: 100vh;
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
