import React from "react";
import styled, { css } from "styled-components";
import NormalMap from "../../assets/img/mapType/normal.png";
import HybridMap from "../../assets/img/mapType/hybrid.png";
import ImageMap from "../../assets/img/mapType/image.png";
import Zizukdo from "../../assets/img/mapType/zizukdo.png";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

interface Props {
  currentType: string;
  onChange: (type: google.maps.MapTypeId, stringType: TStringType) => void;
}

export type TStringType = (typeof stringTypes)[keyof typeof stringTypes];

export const stringTypes = {
  DEFAULT: "일반지도",
  SATELLITE: "위성지도",
  AIRLINE: "항공지도",
  CADASTRAL: "지적도",
};

const GoogleMapTypeController: React.FC<Props> = ({
  currentType,
  onChange,
}) => {
  /**
   * satellite 위성, 항공
   * terrain 일반
   * hybrid 지적도
   */
  return (
    <MapTypeControllerWrap>
      <MapButton
        // checked={currentType === ("일반지도" || naver.maps.MapTypeId.NORMAL)}
        checked={currentType === "일반지도"}
        onClick={() =>
          onChange(google.maps.MapTypeId.TERRAIN, stringTypes.DEFAULT)
        }
      >
        <div>
          <img width="100%" src={NormalMap} alt="일반지도" />
        </div>
        <MapTypeName>일반지도</MapTypeName>
      </MapButton>
      <MapButton
        checked={currentType === "위성지도"}
        onClick={() =>
          onChange(google.maps.MapTypeId.HYBRID, stringTypes.SATELLITE)
        }
      >
        <div>
          <img width="100%" src={HybridMap} alt="위성지도" />
        </div>
        <MapTypeName>위성지도</MapTypeName>
      </MapButton>
      <MapButton
        checked={currentType === "항공지도"}
        onClick={() =>
          onChange(google.maps.MapTypeId.SATELLITE, stringTypes.AIRLINE)
        }
      >
        <div>
          <img width="100%" src={Zizukdo} alt="항공사진" />
        </div>
        <MapTypeName>항공사진</MapTypeName>
      </MapButton>
      <MapButton
        checked={currentType === "지적도"}
        onClick={() =>
          onChange(google.maps.MapTypeId.TERRAIN, stringTypes.CADASTRAL)
        }
      >
        <div>
          <img width="100%" src={ImageMap} alt="지적도" />
        </div>
        <MapTypeName>지적도</MapTypeName>
      </MapButton>
    </MapTypeControllerWrap>
  );
};
export default GoogleMapTypeController;

const MapTypeControllerWrap = styled.div`
  display: flex;
  gap: 0 8px;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 50;
  ${fonts("B4")};
`;
const MapButton = styled.div<{ checked: boolean }>`
  width: 80px;
  background-color: ${colors.WHITE};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  ${({ checked }) =>
    checked &&
    css`
      box-shadow: 0px 0px 0px 1px ${colors.SELECTED_BLUE};
      color: ${colors.SELECTED_BLUE};
    `};
`;
const MapTypeName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
`;
