import React from "react";
import { IHeritageListItem } from "@apis/heritage/interface/response";
import { nullCheck } from "utils";

interface Props {
  info: IHeritageListItem;
}

const MapOverlay: React.FC<Props> = ({ info }) => {
  return (
    <div style={{ fontSize: "14px" }} className="overlay">
      <>
        <div>이름 : {nullCheck(info.label || info.withoutUriId)}</div>
        <div>구분 : {nullCheck(info.typeName)}</div>
        <div>분류 : {nullCheck(info.classificationTypeName)}</div>
        <div>소재지 : {nullCheck(info.place)}</div>
      </>
    </div>
  );
};
export default MapOverlay;
