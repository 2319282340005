import styled from "styled-components";
import { fonts } from "../../theme/fonts";

export const WordCloudWrap = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 0 20px;
`;
export const CategoryWrap = styled.div`
  margin-top: 24px;
  width: 395px;
  height: 587px;
  overflow: auto;
`;
export const WordName = styled.div`
  ${fonts("H6")};
`;
export const SelectedWordInfo = styled.ul`
  list-style: disc;
  padding: 0;
  margin: 0;
  margin-top: 16px;
  ${fonts("B4")};
  list-style-position: inside;
  > li + li {
    margin-top: 8px;
  }
`;
export const SelectedWordEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
