import React from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import Tag from "../Tag";

export interface MemberInfoProps {
  name?: string;
  job?: string;
  skills?: string[];
  position?: string;
  link?: string;
  blank?: boolean;
}

interface Props {
  info: MemberInfoProps;
}

const MemberInfo: React.FC<Props> = ({
  info: { name, job, skills, position = "", link, blank = false },
}) => {
  const handleBrowserOpenClick = () => {
    window.open(link);
  };

  return blank ? (
    <div style={{ width: "300px" }}></div>
  ) : (
    <MemberInfoWrap>
      <MemberInfoTop>
        <MemberName>{name || "-"}</MemberName>
        <MemberJob>{job || "-"}</MemberJob>
      </MemberInfoTop>
      <MemberInfoCenter>
        <MemberSkill>
          {(skills || []).map((skill, index) => {
            if (index < (skills || []).length - 1) {
              return `${skill}, `;
            }
            return skill;
          })}
        </MemberSkill>
      </MemberInfoCenter>
      <MemberInfoBottom>
        <MemberPosition>
          <Tag key={position}>{position}</Tag>
          {link ? (
            <LinkButton onClick={link ? handleBrowserOpenClick : undefined}>
              홈페이지
            </LinkButton>
          ) : null}
        </MemberPosition>
      </MemberInfoBottom>
    </MemberInfoWrap>
  );
};
export default MemberInfo;

const MemberInfoWrap = styled.article`
  width: 300px;
  padding: 30px;
  background-color: ${colors.WHITE};
  border-radius: 16px;
  transition: 0.1s;
`;

const MemberInfoTop = styled.div`
  display: flex;
  align-items: center;
`;
const MemberInfoCenter = styled.div`
  margin-top: 16px;
`;
const MemberInfoBottom = styled.div`
  margin-top: 16px;
`;
const MemberName = styled.div`
  ${fonts("H5")};
`;
const MemberJob = styled.div`
  ${fonts("B5")};
  color: ${colors.GRAY5};
  margin-left: 6px;
`;
const MemberSkill = styled.div`
  ${fonts("B5")}
`;
const MemberPosition = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LinkButton = styled.div`
  ${fonts("B5")};
  padding: 6px 12px;
  color: ${colors.WHITE};
  background-color: ${colors.GRAY1};
  border-radius: 50px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;
