import { StateCreator, create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";

export interface IUseAiComu {
  chat: IChatItem[];
  setChat: (item: IChatItem[]) => void;
  setAiMessage: (item: IChatItem[], index?: number) => void;
  clearMessage: () => void;
}

interface IChatItem {
  id: number;
  type: string;
  message: string;
  status?: string;
  createdDate: string;
}

type TPersist = (
  config: StateCreator<IUseAiComu>,
  options: PersistOptions<IUseAiComu>
) => StateCreator<IUseAiComu>;

const useAiComu = create<IUseAiComu>(
  (persist as TPersist)(
    (set) => ({
      chat: [],
      setChat: (item) =>
        set((state) => {
          const chat = [...state.chat, ...item];
          if (chat.length >= 100) {
            chat.shift();
            chat.shift();
            return { chat };
          }
          return { chat };
        }),
      setAiMessage: (item, index) =>
        set((state) => {
          const chat = [
            ...state.chat.filter((item) => item.status !== "read"),
            ...item,
          ];
          if (chat.length >= 100) {
            chat.shift();
            chat.shift();
            return { chat };
          }
          return { chat };
        }),
      clearMessage: () => set(() => ({ chat: [] })),
    }),
    {
      name: "ai-comu",
    }
  )
);

export default useAiComu;
