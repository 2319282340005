import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { HashPage } from ".";
import img1 from "../../assets/img/heritage/heritage1.png";
import img2 from "../../assets/img/heritage/heritage2.png";
import img3 from "../../assets/img/heritage/heritage3.png";
import img4 from "../../assets/img/heritage/heritage4.png";
import img5 from "../../assets/img/heritage/heritage5.png";
import IntroButton from "../../components/Button/IntroButton";

interface Props {
  hash: HashPage;
}

const Heritage: React.FC<Props> = ({ hash }) => {
  const imageRef = useRef<HTMLElement[]>([]);
  const textRef = useRef<HTMLDivElement | null>(null);
  const navigation = useNavigate();
  useEffect(() => {
    if (hash === "#page3") {
      setTimeout(() => {
        imageRef.current[0].style.animationName = "move1";
        imageRef.current[1].style.animationName = "move2";
        imageRef.current[2].style.animationName = "move3";
        imageRef.current[3].style.animationName = "move4";
        imageRef.current[4].style.animationName = "move5";
        if (textRef.current) {
          textRef.current.style.animationName = "slideIn";
        }
      }, 200);
    }
  }, [hash]);
  const handleClickDetail = () => {
    navigation("/heritage");
  };
  return (
    <Background>
      <Img1
        ref={(el) => {
          if (el) {
            imageRef.current[0] = el;
          }
        }}
        src={img1}
        width="17%"
      />
      <Img2
        ref={(el) => {
          if (el) {
            imageRef.current[1] = el;
          }
        }}
        src={img2}
        width="17%"
      />
      <Img3
        ref={(el) => {
          if (el) {
            imageRef.current[2] = el;
          }
        }}
        src={img3}
        width="17%"
      />
      <Img4
        ref={(el) => {
          if (el) {
            imageRef.current[3] = el;
          }
        }}
        src={img4}
        width="17%"
      />
      <Img5
        ref={(el) => {
          if (el) {
            imageRef.current[4] = el;
          }
        }}
        src={img5}
        width="17%"
      />
      <HeritageTitleWrap ref={textRef}>
        <HeritageTitle>문화자산</HeritageTitle>
        <HeritageInfo>
          “문화자산” 페이지에서는 국보, 보물 등 주요 지정문화재 및 비지정문화재
          뿐만 아니라,
          <br />
          지역의 역사적/문화적 가치를 재발견할 수 있는
          <br />
          인물, 사건, 장소 등 다양한 지역 역사문화자산 정보를 검색할 수
          있습니다.
        </HeritageInfo>
        <IntroButton onClick={handleClickDetail}>더 알아보기</IntroButton>
      </HeritageTitleWrap>
    </Background>
  );
};
export default Heritage;

const Background = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: white;
`;
const ImageDefaultStyle = css`
  position: absolute;
  animation-duration: 0.8s;
  animation-fill-mode: both;
`;

const Img1 = styled.img`
  ${ImageDefaultStyle};
  top: 100%;
  left: 29%;
  @keyframes move1 {
    from {
      top: 100%;
    }
    to {
      top: 16%;
    }
  }
`;
const Img2 = styled.img`
  ${ImageDefaultStyle}
  right: 20%;
  top: 100%;
  @keyframes move2 {
    from {
      top: 100%;
    }
    to {
      top: -1%;
    }
  }
`;
const Img3 = styled.img`
  ${ImageDefaultStyle}
  left: 20%;
  bottom: -100%;
  @keyframes move3 {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
`;
const Img4 = styled.img`
  ${ImageDefaultStyle}
  bottom: -100%;
  right: 25%;
  z-index: 3;
  @keyframes move4 {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
`;
const Img5 = styled.img`
  ${ImageDefaultStyle}
  bottom: -100%;
  right: 0%;
  @keyframes move5 {
    from {
      bottom: -100%;
    }
    to {
      bottom: 10%;
    }
  }
`;
const HeritageTitleWrap = styled.div`
  width: 930px;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  opacity: 0;
  z-index: 5;
  @keyframes slideIn {
    from {
      top: 60%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
  color: black;
`;
const HeritageTitle = styled.h2`
  display: inline-block;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
`;
const HeritageInfo = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
`;
