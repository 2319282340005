import React, { useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Prev } from "../../assets/img/icon/left.svg";
import { ReactComponent as Next } from "../../assets/img/icon/right.svg";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { ImageSlideOptions } from "../ImageSlide";

interface Props extends ImageSlideOptions {
  title: string;
}

const SlideWidthHeader: React.FC<Props> = ({
  title,
  imageWidth = 170,
  gap = 8,
  children,
  slideWidth = 560,
}) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const TRANSFORM_AMOUT = imageWidth + gap;

  let transform = 0;

  const handleClickPrev = () => {
    if (slideRef.current && transform > 0) {
      slideRef.current.style.transform = `translate(${-(
        --transform * TRANSFORM_AMOUT
      )}px)`;
    }
  };
  const handleClickNext = () => {
    if (slideRef.current) {
      const childCount = slideRef.current.childElementCount;
      if (
        transform <
        childCount -
          Math.floor((slideWidth - (gap * childCount - 1)) / imageWidth)
      ) {
        slideRef.current.style.transform = `translate(${-(
          ++transform * TRANSFORM_AMOUT
        )}px)`;
      }
    }
  };
  return (
    <div>
      <SlideHeaderWrap>
        <div>{title}</div>
        <div>
          <PrevButton onClick={handleClickPrev} />
          <NextButton onClick={handleClickNext} />
        </div>
      </SlideHeaderWrap>
      <Slide>
        <ImageListWrap style={{ width: slideWidth }}>
          <ImageList ref={slideRef} gap={gap}>
            {children}
          </ImageList>
        </ImageListWrap>
      </Slide>
    </div>
  );
};
export default SlideWidthHeader;

const Slide = styled.div`
  position: relative;
`;
const PrevButton = styled(Prev)`
  cursor: pointer;
`;
const NextButton = styled(Next)`
  cursor: pointer;
  margin-left: 24px;
`;
const ImageListWrap = styled.div`
  overflow: hidden;
  padding-top: 30px;
`;
const ImageList = styled.div<{ gap: number }>`
  display: flex;
  gap: ${({ gap }) => `0 ${gap}px`};
  transition: 1s;
`;
const SlideHeaderWrap = styled.div`
  ${fonts("H3")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`;
