import React from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { nullCheck } from "../../utils";
import { IHeritageListItem } from "@apis/heritage/interface/response";
import Agency from "../../assets/img/heritage/agency.png";
import Character from "../../assets/img/heritage/character.png";
import Event from "../../assets/img/heritage/event.png";
import Heritage from "../../assets/img/heritage/heritage.png";
import Individual from "../../assets/img/heritage/individual.png";
import Location from "../../assets/img/heritage/location.png";
import Organ from "../../assets/img/heritage/organization.png";

export interface HeritageInfo {
  id: number;
  url?: string;
  title?: string;
  category?: string;
  classification?: string;
  address?: string;
}

interface Props {
  info: IHeritageListItem;
  onClick: (info: IHeritageListItem) => void;
  isSelected: boolean;
}

const HeritageListCardButton: React.FC<Props> = ({
  info,
  onClick,
  isSelected,
}) => {
  const getImage = (type?: string) => {
    switch (type) {
      case "인물":
        return Character;
      case "단체":
        return Organ;
      case "기관":
        return Agency;
      case "개체":
        return Individual;
      case "문화유산":
        return Heritage;
      case "장소":
        return Location;
      case "사건":
        return Event;
      default:
        return Individual;
    }
  };

  return (
    <HeritageSearchItem
      isSelected={isSelected}
      onClick={() => {
        onClick(info);
      }}
    >
      <HeritageSearchItemImageWrap>
        <img src={info.image ?? getImage(info.protoTypeName)} alt="thum" />
        {isSelected && (
          <SelectedCardImage>
            <SelectedCardImageLabel>선택됨</SelectedCardImageLabel>
          </SelectedCardImage>
        )}
      </HeritageSearchItemImageWrap>
      <HeritageSearchItemInfoWrap>
        <HeritageSearchItemDetailInfoTop>
          <HeritageSearchItemName>
            {nullCheck(info?.label || info?.withoutUriId || "-")}
            {info.cnName && `(${info.cnName})`}
          </HeritageSearchItemName>
        </HeritageSearchItemDetailInfoTop>
        <HeritageSearchItemDetailInfoWrap>
          <HeritageSearchItemDetailInfoItem>
            <div>구분</div>
            <div>{nullCheck(info.typeName)}</div>
          </HeritageSearchItemDetailInfoItem>
          <HeritageSearchItemDetailInfoItem>
            <div>지정분류</div>
            <div>{nullCheck(info.classificationTypeName)}</div>
          </HeritageSearchItemDetailInfoItem>
          <HeritageSearchItemDetailInfoItem>
            <div>소재지</div>
            <div>{nullCheck(info.place)}</div>
          </HeritageSearchItemDetailInfoItem>
        </HeritageSearchItemDetailInfoWrap>
      </HeritageSearchItemInfoWrap>
    </HeritageSearchItem>
  );
};
export default HeritageListCardButton;

const HeritageSearchItem = styled.article<{ isSelected: boolean }>`
  display: flex;
  gap: 0 24px;
  background-color: ${({ isSelected }) =>
    isSelected ? colors.GRAY10 : colors.WHITE};
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;
const HeritageSearchItemImageWrap = styled.div`
  position: relative;
  width: 145px;
  height: 113px;
  border-radius: 8px;
  overflow: hidden;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    object-fit: cover;
    z-index: 10;
  }
`;
const SelectedCardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 11;
`;
const SelectedCardImageLabel = styled.div`
  padding: 6px 16px;
  color: ${colors.WHITE};
  ${fonts("B5")};
  background-color: ${colors.GRAY1};
  border-radius: 4px;
`;
const HeritageSearchItemInfoWrap = styled.div`
  flex-grow: 1;
  padding: 15px 0;
`;
const HeritageSearchItemDetailInfoWrap = styled.div`
  display: flex;
  gap: 0 36px;
  margin-top: 16px;
`;
const HeritageSearchItemName = styled.h3`
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fonts("H5")}
`;
const HeritageSearchItemDetailInfoItem = styled.div`
  > div:nth-child(1) {
    ${fonts("B5")};
    color: ${colors.GRAY5};
  }
  > div:nth-child(2) {
    margin-top: 4px;
    ${fonts("B4")};
    color: ${colors.GRAY1};
  }
`;
const HeritageSearchItemDetailInfoTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const HeritageSearchItemDetailButton = styled.div`
  ${fonts("B4")};
  cursor: pointer;
`;
