import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HashPage } from ".";
import earth from "../../assets/bg/earth.png";
import IntroButton from "../../components/Button/IntroButton";

interface Props {
  hash: HashPage;
}

const Place: React.FC<Props> = ({ hash }) => {
  const earthRef = useRef<HTMLImageElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const navigation = useNavigate();
  useEffect(() => {
    if (hash === "#page4") {
      setTimeout(() => {
        if (earthRef.current) {
          earthRef.current.style.animationName = "rise";
        }
        if (textRef.current) {
          textRef.current.style.animationName = "slideIn";
        }
      }, 200);
    }
  }, [hash]);
  const handleClickDetail = () => {
    navigation("/place");
  };

  return (
    <Background>
      <Earth ref={earthRef} src={earth} />
      <PlaceTitleWrap ref={textRef}>
        <PlaceTitle>공간정보</PlaceTitle>
        <PlaceInfo>
          지역별, 공간별 문화자산을 지도에서 검색할 수 있습니다.
          <br />
          또한, 지역별 문화자산에 대한 세부 정보, 공간정보,
          <br />
          고해상도 항공사진 및 20세기초 지적도 등을 확인할 수 있습니다.
        </PlaceInfo>
        <IntroButton onClick={handleClickDetail}>더 알아보기</IntroButton>
      </PlaceTitleWrap>
    </Background>
  );
};
export default Place;

const Background = styled.div`
  position: relative;
  height: 100vh;
  background-color: black;
  overflow: hidden;
`;
const Earth = styled.img`
  width: 120%;
  position: absolute;
  object-fit: cover;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  animation-duration: 0.8s;
  animation-fill-mode: both;
  z-index: 0;
  @keyframes rise {
    from {
      bottom: -100%;
      transform: scale(0.1), translateX(-50%);
    }
    to {
      bottom: -30%;
      transform: scale(0.1), translateX(-50%);
    }
  }
`;
const PlaceTitleWrap = styled.div`
  width: 930px;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  opacity: 0;
  z-index: 5;
  @keyframes slideIn {
    from {
      top: 60%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
  color: white;
`;
const PlaceTitle = styled.h2`
  display: inline-block;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
`;
const PlaceInfo = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
`;
