import React from "react";
import styled from "styled-components";
import { ReactComponent as CancelSvg } from "../../assets/img/icon/circle_cancel.svg";
import { fonts } from "../../theme/fonts";
import { IHeritageListItem } from "@apis/heritage/interface/response";

interface Props {
  info: IHeritageListItem;
  onHeritageRemoveClick: (name: IHeritageListItem) => void;
}

const RemovableLabel: React.FC<Props> = ({ info, onHeritageRemoveClick }) => {
  return (
    <RemovableLabelWrap>
      <RemovableLabelText>
        {info?.label || info?.withoutUriId}
      </RemovableLabelText>
      <RemoveButton onClick={() => onHeritageRemoveClick(info)} />
    </RemovableLabelWrap>
  );
};
export default RemovableLabel;

const RemovableLabelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;
const RemovableLabelText = styled.div`
  ${fonts("B3")};
`;
const RemoveButton = styled(CancelSvg)`
  cursor: pointer;
`;
