import React, { useCallback, useEffect, useState } from "react";
import { Word } from "react-wordcloud";
import styled from "styled-components";
import { wordCloudExplain } from "../../dummy";
import { fonts } from "../../theme/fonts";
import ContentLayout from "../ContentLayout";
import WordCloud, { WordCloudOption } from "../WordCloud";
import {
  CategoryWrap,
  SelectedWordEmpty,
  SelectedWordInfo,
  WordCloudWrap,
  WordName,
} from "./styles";
import { requestHeritageWordCloudCategories } from "@apis";
import { useMutation } from "react-query";

interface Props {
  data: WordCloudOption[];
}

const WordCloudWithInfo: React.FC<Props> = ({ data }) => {
  const [selectedWord, setSelectedWord] = useState<string>();
  const [wordCategory, setWordCategory] = useState<string[]>([]);

  /** Word Cloud Category */
  const { mutateAsync: wordCloudCategories } = useMutation(
    requestHeritageWordCloudCategories
  );

  const handleSelectWordCloud = useCallback(
    async (wordData: Word) => {
      await wordCloudCategories({ id: wordData.id }).then((datas) => {
        setSelectedWord(wordData.text);
        setWordCategory(datas?.rows || []);
      });
    },
    [wordCloudCategories]
  );

  useEffect(() => {
    if (data?.length === 0) {
      setSelectedWord("");
      setWordCategory([]);
    }
  }, [data]);

  return (
    <WordCloudWrap>
      <ContentLayout>
        <SubTitle>워드 클라우드</SubTitle>
        <WordCloud data={data} onWordClick={handleSelectWordCloud} />
      </ContentLayout>
      <ContentLayout>
        <div>
          <SubTitle>연관 키워드</SubTitle>
          <CategoryWrap>
            {selectedWord ? (
              <>
                <WordName>{selectedWord}</WordName>
                <SelectedWordInfo>
                  {wordCategory?.map?.((data: string) => (
                    <li key={data}>{data}</li>
                  ))}
                </SelectedWordInfo>
              </>
            ) : (
              <SelectedWordEmpty>단어를 선택해 주세요</SelectedWordEmpty>
            )}
          </CategoryWrap>
        </div>
      </ContentLayout>
    </WordCloudWrap>
  );
};
export default WordCloudWithInfo;

const SubTitle = styled.h3`
  ${fonts("H3")};
`;
