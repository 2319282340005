import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../theme/colors";
import { ReactComponent as ArrowRight } from "../../assets/img/icon/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../assets/img/icon/ArrowLeft.svg";
import { ReactComponent as ArrowLeftDouble } from "@assets/img/icon/ArrowLeft-Double.svg";
import { ReactComponent as ArrowRightDouble } from "@assets/img/icon/ArrowRight-Double.svg";
// import bg from "../../assets/bg/projectbg.jpg";
// import bg from "../../assets/bg/projectbg.jpg";

interface Props {
  totalCount?: number;
  pageSize?: number;
  page?: number;
  onCurrentChange: (current: number) => void;
  maxLength?: number;
}

const Pagination: React.FC<Props> = ({
  totalCount = 0,
  page = 1,
  onCurrentChange,
  pageSize = 10,
  maxLength = 5,
}) => {
  const dividedTotalCount = Math.ceil(totalCount / pageSize);
  // 총 데이터의 수를 페이지사이즈로 나눈 값

  useEffect(() => {
    if (totalCount !== 0 && page > dividedTotalCount) {
      throw new Error("현재 페이지 번호가 총 페이지 수보다 작아야 합니다.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  // 현재 페이지를 잘못 입력하면 에러

  const render = () => {
    const pageArray: React.ReactNode[] = [];
    // 페이지 버튼이 담겨있는 배열

    const startNumber =
      page % maxLength === 0
        ? (Math.floor(page / maxLength) - 1) * maxLength
        : Math.floor(page / maxLength) * maxLength;
    // 페이지 넘버 시작 숫자

    const endNumber =
      page % maxLength === 0
        ? (Math.floor(page / maxLength) - 1) * maxLength + maxLength
        : Math.floor(dividedTotalCount / maxLength) * maxLength + 1 > page
        ? Math.floor(page / maxLength) * maxLength + maxLength
        : Math.floor(page / maxLength) * maxLength +
          (dividedTotalCount % maxLength);
    // 페이지 넘버 마지막 숫자

    for (let i = startNumber; i < endNumber; i += 1) {
      pageArray.push(
        <ButtonWrap disabled={false} key={i}>
          <PageNumber
            current={i === page - 1}
            onClick={() => onCurrentChange(i + 1)}
          >
            {i + 1}
          </PageNumber>
        </ButtonWrap>
      );
    }

    return pageArray;
  };
  const jumpPrev = (): number => {
    if (page - maxLength < 1) {
      return 1;
    }
    return page - maxLength;
  };
  const jumpNext = (): number => {
    if (page + maxLength > dividedTotalCount) {
      return dividedTotalCount;
    }
    return page + maxLength;
  };
  return (
    <PaginationWrap>
      <ButtonWrap
        disabled={!(page - 1 >= 1)}
        onClick={() => page - 1 >= 1 && onCurrentChange(jumpPrev())}
      >
        <DoublePrevButton disabled={!(page - 1 >= 1)} />
      </ButtonWrap>
      <ButtonWrap
        disabled={!(page - 1 >= 1)}
        onClick={() => page - 1 >= 1 && onCurrentChange(page - 1)}
      >
        <PrevButton disabled={!(page - 1 >= 1)} />
      </ButtonWrap>
      {render()}
      <ButtonWrap
        disabled={!(page < dividedTotalCount)}
        onClick={() => page < dividedTotalCount && onCurrentChange(page + 1)}
      >
        <NextButton disabled={!(page < dividedTotalCount)} />
      </ButtonWrap>
      <ButtonWrap
        disabled={!(page < dividedTotalCount)}
        onClick={() => page < dividedTotalCount && onCurrentChange(jumpNext())}
      >
        <DoubleNextButton disabled={!(page < dividedTotalCount)} />
      </ButtonWrap>
    </PaginationWrap>
  );
};
export default Pagination;

const getCurrentStyle = (current: boolean = false) => {
  if (current) {
    return css`
      background-color: ${colors.GRAY1};
      color: ${colors.GRAY10};
    `;
  }
  return css``;
};

const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;
const ButtonWrap = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;
const PageNumber = styled.div<{ current: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  ${({ current }) => getCurrentStyle(current)};
`;
const NextButton = styled(ArrowRight)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  & > g > path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.GRAY6}` : `${colors.GRAY2}`};
  }
`;
const DoubleNextButton = styled(ArrowRightDouble)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  & > g > path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.GRAY6}` : `${colors.GRAY2}`};
  }
`;
const PrevButton = styled(ArrowLeft)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  & > g > path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.GRAY6}` : `${colors.GRAY2}`};
  }
`;
const DoublePrevButton = styled(ArrowLeftDouble)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  & > g > path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.GRAY6}` : `${colors.GRAY2}`};
  }
`;
