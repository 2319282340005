import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";

interface Props {
  children: ReactNode;
  padding?: 22 | 30;
}

const ContentLayout: React.FC<Props> = ({ children, padding = 30 }) => {
  return <Layout padding={padding}>{children}</Layout>;
};
export default ContentLayout;

const Layout = styled.div<{ padding: number }>`
  background: ${colors.WHITE};
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: ${({ padding }) => padding}px;
`;
