import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
    html,body,#root {
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    div,header {
        box-sizing: border-box;
    }
    ul {
        list-style: none;
    }
    h1,h2,h3,h4,h5,h6,figure,p,hr{
        margin: 0;
    }
    a {
        color: inherit;
        text-decoration: none;
        &:visited {
            color:inherit;
        }
    }
    img {
        margin: 0;
    }
    /* input::placeholder {
        font-size: 18px;
        font-weight : 500;
        line-height : 19.6px;
    } */
    .overlay {
        
        border:0px solid transparent;
        padding: 9px 12px;

    }
`;
export default GlobalStyle;
