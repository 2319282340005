import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { routePath } from "../../constants";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { nullCheck } from "../../utils";
import { ReactComponent as RemoveSvg } from "../../assets/img/icon/cancel.svg";
import MyButton from "../Button";
import { IHeritageListItem } from "@apis/heritage/interface/response";
import Agency from "../../assets/img/heritage/agency.png";
import Character from "../../assets/img/heritage/character.png";
import Event from "../../assets/img/heritage/event.png";
import Heritage from "../../assets/img/heritage/heritage.png";
import Individual from "../../assets/img/heritage/individual.png";
import Location from "../../assets/img/heritage/location.png";
import Organ from "../../assets/img/heritage/organization.png";

export interface HeritageInfo {
  id: number;
  url?: string;
  title?: string;
  category?: string;
  classification?: string;
  address?: string;
}

interface Props {
  info: IHeritageListItem | any;
  onRemove?: (info: IHeritageListItem) => void;
  isLink?: boolean;
}

const HeritageListCard: React.FC<Props> = ({ info, onRemove, isLink }) => {
  const getImage = (type?: string) => {
    switch (type) {
      case "인물":
        return Character;
      case "단체":
        return Organ;
      case "기관":
        return Agency;
      case "개체":
        return Individual;
      case "문화유산":
        return Heritage;
      case "장소":
        return Location;
      case "사건":
        return Event;
      default:
        return Individual;
    }
  };

  if (isLink) {
    return (
      <article>
        <HeritageLinkItem
          to={`${routePath.HERITAGE}/${encodeURIComponent(info.id)}`}
        >
          <HeritageSearchItem>
            <HeritageSearchItemImageWrap>
              <img
                src={info.image ?? getImage(info.protoTypeName)}
                alt="thum"
              />
            </HeritageSearchItemImageWrap>
            <HeritageSearchItemInfoWrap>
              <HeritageSearchItemDetailInfoTop>
                <HeritageSearchItemName>
                  {nullCheck(info.label || info.withoutUriId)}
                  {info.cnName && `(${info.cnName})`}
                </HeritageSearchItemName>
                {onRemove && (
                  <RemoveButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(info);
                    }}
                  />
                )}
              </HeritageSearchItemDetailInfoTop>
              <HeritageSearchItemDetailInfoWrap>
                <HeritageSearchItemDetailInfoItem>
                  <div>구분</div>
                  <div>{nullCheck(info.typeName)}</div>
                </HeritageSearchItemDetailInfoItem>
                <HeritageSearchItemDetailInfoItem>
                  <div>지정분류</div>
                  <div>{nullCheck(info.classificationTypeName)}</div>
                </HeritageSearchItemDetailInfoItem>
                <HeritageSearchItemDetailInfoItem>
                  <div>소재지</div>
                  <div>{nullCheck(info.place)}</div>
                </HeritageSearchItemDetailInfoItem>
              </HeritageSearchItemDetailInfoWrap>
            </HeritageSearchItemInfoWrap>
          </HeritageSearchItem>
        </HeritageLinkItem>
      </article>
    );
  }
  return (
    <HeritageSearchItem>
      <HeritageSearchItemImageWrap>
        <img src={info.image ?? getImage(info.typeWithoutUri)} alt="thum" />
      </HeritageSearchItemImageWrap>
      <HeritageSearchItemInfoWrap>
        <HeritageSearchItemDetailInfoTop>
          <HeritageSearchItemName>
            {nullCheck(info.label || info.withoutUriId)}
            {info.cnName && `(${info.cnName})`}
          </HeritageSearchItemName>
          {onRemove && <RemoveButton onClick={() => onRemove(info)} />}
        </HeritageSearchItemDetailInfoTop>
        <HeritageSearchItemDetailInfoWrap>
          <HeritageSearchItemDetailInfoItem>
            <div>구분</div>
            <div>{nullCheck(info.typeName)}</div>
          </HeritageSearchItemDetailInfoItem>
          <HeritageSearchItemDetailInfoItem>
            <div>지정분류</div>
            <div>{nullCheck(info.classificationTypeName)}</div>
          </HeritageSearchItemDetailInfoItem>
          <HeritageSearchItemDetailInfoItem>
            <div>소재지</div>
            <div>{nullCheck(info.place)}</div>
          </HeritageSearchItemDetailInfoItem>
        </HeritageSearchItemDetailInfoWrap>
        <HeritageSearchItemDetailButtonWrap>
          <MyButton
            onClick={() =>
              window.open(
                `${routePath.HERITAGE}/${encodeURIComponent(info.id)}`,
                "_blank"
              )
            }
          >
            더 알아보기
          </MyButton>
        </HeritageSearchItemDetailButtonWrap>
      </HeritageSearchItemInfoWrap>
    </HeritageSearchItem>
  );
};
export default HeritageListCard;

const HeritageSearchItem = styled.div`
  display: flex;
  gap: 0 24px;
  background-color: ${colors.WHITE};
  padding: 16px;
  border-radius: 16px;
  position: relative;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: ${colors.GRAY10};
    & > div:nth-child(1) > img {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;
const HeritageSearchItemImageWrap = styled.div`
  position: relative;
  width: 145px;
  height: 113px;
  border-radius: 8px;
  overflow: hidden;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    object-fit: cover;
    transition: 0.1s linear;
  }
`;
const HeritageSearchItemInfoWrap = styled.div`
  flex-grow: 1;
  padding: 15px 0;
`;
const HeritageSearchItemDetailInfoWrap = styled.div`
  display: flex;
  gap: 0 36px;
  margin-top: 16px;
`;
const HeritageSearchItemDetailButtonWrap = styled.div`
  position: absolute;
  right: 16px;
  bottom: 12px;
  width: 130px;
  word-break: keep-all;
`;
const HeritageSearchItemName = styled.h3`
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fonts("H5")}
`;
const HeritageSearchItemDetailInfoItem = styled.div`
  > div:nth-child(1) {
    ${fonts("B5")};
    color: ${colors.GRAY5};
  }
  > div:nth-child(2) {
    margin-top: 4px;
    ${fonts("B4")};
    color: ${colors.GRAY1};
  }
`;
const HeritageSearchItemDetailInfoTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const RemoveButton = styled(RemoveSvg)`
  cursor: pointer;
`;
const HeritageLinkItem = styled(Link)`
  margin-top: 8px;
`;
