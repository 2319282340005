import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HashPage } from ".";
import AIComuBg from "../../assets/bg/aicomu.png";
import IntroButton from "../../components/Button/IntroButton";

interface Props {
  hash: HashPage;
}

const AIComu: React.FC<Props> = ({ hash }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (hash === "#page6") {
      setTimeout(() => {
        if (imageRef.current) {
          imageRef.current.style.animationName = "sizeUp";
        }
        if (textRef.current) {
          textRef.current.style.animationName = "slideIn";
        }
      }, 200);
    }
  }, [hash]);
  const handleClickDetail = () => {
    navigation("/AIComu");
  };
  return (
    <Background>
      <AIComuImage ref={imageRef} src={AIComuBg} />
      <AIComuTitleWrap ref={textRef}>
        <AIComuTitle>AI 대화</AIComuTitle>
        <AIComuInfo>
          플랫폼 DB내의 온톨로지 정보를 기반으로 다양한 관점의 정보를 질문하실
          수 있습니다.
          <br />
          텍스트 기반의 대화형 인공지능 챗봇을 통해서 특정 지역역사문화자산에
          대해서 질문하실 수도 있으며,
          <br />
          기존의 정보를 기반으로 새로운 역사적 관점에 대한 이야기도 들으실 수
          있습니다.
        </AIComuInfo>
        <IntroButton onClick={handleClickDetail}>더 알아보기</IntroButton>
      </AIComuTitleWrap>
    </Background>
  );
};
export default AIComu;

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;
const AIComuImage = styled.img`
  position: absolute;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  @keyframes sizeUp {
    from {
      width: 100%;
    }
    to {
      width: 130%;
    }
  }
`;
const AIComuTitleWrap = styled.div`
  width: 930px;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  opacity: 0;
  z-index: 5;
  @keyframes slideIn {
    from {
      top: 60%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
  color: black;
`;
const AIComuTitle = styled.h2`
  display: inline-block;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
  color: white;
`;
const AIComuInfo = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: white;
`;
