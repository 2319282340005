import React, { useEffect, useRef, useState } from "react";
import Introduction from "./Introduction";
import styled, { css } from "styled-components";
import { ReactComponent as WhiteLogo } from "../../assets/img/icon/logo_w.svg";
import { ReactComponent as BlackLogo } from "../../assets/img/icon/logo.svg";
import { fonts } from "../../theme/fonts";
import { SectionsContainer, Section } from "react-fullpage";
import { useLocation } from "react-router-dom";
import Heritage from "./Heritage";
import Place from "./Place";
import Network from "./Network";
import { colors } from "../../theme/colors";
import useDidMount from "../../hooks/useIsMount";
import VideoPage from "./VideoPage";
import { ReactComponent as Mouse } from "../../assets/img/icon/mouse.svg";
import VRDimen from "./VRDimen";
import AIComu from "./AIComu";

interface Props {}

export type HashPage =
  | "#page1"
  | "#page2"
  | "#page3"
  | "#page4"
  | "#page5"
  | "#page6"
  | "#page7";

const Home: React.FC<Props> = () => {
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [pageHash, setPageHash] = useState<HashPage>("#page1");
  /** 마운트 직후 감지 hook */
  const { isMount } = useDidMount();

  let options = {
    activeClass: "active",
    anchors: ["page1", "page2", "page3", "page4", "page5", "page6", "page7"],
    arrowNavigation: true,
    className: "SectionContainer",
    delay: 800,
    navigation: false,
    scrollBar: false,
    sectionClassName: "Section",
    sectionPaddingTop: "0",
    sectionPaddingBottom: "0",
    verticalAlign: false,
    activeSection: 0,
  };

  /** Page Header Rendering */
  const renderPageHeader = (hash: HashPage) => {
    if (hash !== "#page1") {
      return (
        <Header>
          {getLogo(pageHash)}
          <MainTitle color={getImageStyle(pageHash)}>
            Korean Cultural Heritage Resources
          </MainTitle>
        </Header>
      );
    }
    return null;
  };

  /** 섹션 위치 별 컬러 지정 hash Setting */
  const getImageStyle = (hash: HashPage) => {
    switch (hash) {
      case "#page1":
      case "#page2":
      case "#page4":
      case "#page6":
      case "#page7":
        return "white";
      case "#page3":
      case "#page5":
        return "black";
      default:
        return "white";
    }
  };

  /** 섹션 위치 별 로고 지정 white/black */
  const getLogo = (hash: HashPage) => {
    switch (hash) {
      case "#page1":
      case "#page2":
      case "#page4":
      case "#page6":
      case "#page7":
        return <WhiteLogo width={24} height={24} />;
      case "#page3":
      case "#page5":
        return <BlackLogo width={24} height={24} />;

      default:
        return <WhiteLogo width={24} height={24} />;
    }
  };

  useEffect(() => {
    if (isMount) {
      setPageHash(location.hash as HashPage);
    }
  }, [location, isMount]);

  // 첫페이지로 이동
  useEffect(() => {
    window.location.href = "/#page1";
  }, []);

  return (
    <HomeWrap ref={wrapRef}>
      {renderPageHeader(location.hash as HashPage)}
      <SectionsContainer controlls {...options}>
        <Section>
          <VideoPage />
        </Section>
        <Section>
          <Introduction hash={pageHash} />
        </Section>
        <Section>
          <Heritage hash={pageHash} />
        </Section>
        <Section>
          <Place hash={pageHash} />
        </Section>
        <Section>
          <Network hash={pageHash} />
        </Section>
        <Section>
          <AIComu hash={pageHash} />
        </Section>
        <Section>
          <VRDimen hash={pageHash} />
        </Section>
      </SectionsContainer>
      <Navigation hash={location.hash as HashPage}>
        <ul>
          <NavItem
            selected={location.hash === "#page1" || location.hash === ""}
          >
            <a href="#page1" className="opa">
              인트로
            </a>
          </NavItem>
          <NavItem selected={location.hash === "#page2"}>
            <a href="#page2" className="opa">
              프로젝트 소개
            </a>
          </NavItem>
          <NavItem selected={location.hash === "#page3"}>
            <a href="#page3" className="opa">
              문화자산
            </a>
          </NavItem>
          <NavItem selected={location.hash === "#page4"}>
            <a href="#page4" className="opa">
              공간정보
            </a>
          </NavItem>
          <NavItem selected={location.hash === "#page5"}>
            <a href="#page5" className="opa">
              네트워크
            </a>
          </NavItem>
          <NavItem selected={location.hash === "#page6"}>
            <a href="#page6" className="opa">
              AI 대화
            </a>
          </NavItem>
          <NavItem selected={location.hash === "#page7"}>
            <a href="#page7" className="opa">
              가상공간
            </a>
          </NavItem>
        </ul>
      </Navigation>
      <Footer>
        <Mouse
          stroke={getImageStyle(pageHash)}
          fill={getImageStyle(pageHash)}
        />
      </Footer>
    </HomeWrap>
  );
};
export default Home;

/** 섹션 위치 별 하단 마우스 아이콘 및 사이드 메뉴 색상 변경 */
const getNavColor = (hash: HashPage) => {
  switch (hash) {
    case "#page1":
    case "#page2":
    case "#page4":
    case "#page6":
    case "#page7":
      return css`
        color: ${colors.WHITE};
      `;
    case "#page3":
    case "#page5":
      return css`
        color: ${colors.GRAY1};
      `;
    default:
      return css`
        color: ${colors.WHITE};
      `;
  }
};

const HomeWrap = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

const MainTitle = styled.h1<{ color: string }>`
  margin-left: 10px;
  ${fonts("D1")};
  color: ${({ color }) => color};
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`;
const Navigation = styled.div<{ hash: HashPage }>`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  z-index: 10;
  ${({ hash }) => getNavColor(hash)}
  > ul {
    margin: 0;
    padding: 0;
    text-align: right;
  }
`;
const NavItem = styled.li<{ selected: boolean }>`
  ${({ selected }) =>
    selected
      ? css`
          ${fonts("H6")};
        `
      : css`
          ${fonts("B5")};
        `};
  margin-top: 16px;
`;
const Footer = styled.footer`
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  animation: mouseMove 0.6s infinite alternate-reverse;
  @keyframes mouseMove {
    from {
      bottom: 40px;
    }
    to {
      bottom: 50px;
    }
  }
`;
