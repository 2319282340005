import React, { useEffect, useMemo, useState } from "react";
import FilterCollapse from "./FilterCollapse";

interface Props {
  values: any[];
  categoryValues: any[];
  onChange: (value: any) => void;
  onChangeCategory: (value: any) => void;
}

const subjectOption = ["인물", "기관", "단체"];
const eventOption = ["생성", "선교", "이동", "전쟁", "제작", "파괴", "행동"];
const placeOption = ["장소명", "지명", "집단기억"];
const heritageOption = [
  "기록유산",
  "등록문화재",
  "무형문화재",
  "유물",
  "유적건조물",
  "자연유산",
];

const categoryOption = [
  "국보",
  "보물",
  "사적",
  "국가무형문화재",
  "국가민속문화재",
  "시도유형문화재",
  "시도민속문화재",
  "시도기념물",
  "시도향토유적",
  "문화재자료",
  "매장문화재",
  "일반동산문화재",
  "기타비지정문화재",
];

const Filter: React.FC<Props> = ({
  onChange,
  onChangeCategory,
  values,
  categoryValues,
}) => {
  // const checkIncludeHeritage = useMemo(() => {
  //   for (let i = 0; i < heritageOption.length; i++) {
  //     if (values.includes(heritageOption[i])) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [values]);

  // useEffect(() => {
  //   if (!checkIncludeHeritage)
  //     categoryValues.forEach((item) => onChangeCategory(item));
  // }, [checkIncludeHeritage]);

  return (
    <div>
      <FilterCollapse
        title="주체"
        options={subjectOption}
        onChange={onChange}
        values={values}
      />
      <FilterCollapse
        title="사건"
        options={eventOption}
        onChange={onChange}
        values={values}
      />
      <FilterCollapse
        title="장소"
        options={placeOption}
        onChange={onChange}
        values={values}
      />
      <FilterCollapse
        title="개체"
        options={["개체"]}
        onChange={onChange}
        values={values}
      />
      <FilterCollapse
        title="문화유산"
        options={heritageOption}
        onChange={onChange}
        values={values}
      />
      {/* {checkIncludeHeritage && ( */}
      <FilterCollapse
        title="지정분류"
        options={categoryOption}
        onChange={onChangeCategory}
        values={categoryValues}
      />
      {/* )} */}
    </div>
  );
};
export default Filter;
