import React from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

interface Props {
  children: string;
}

const Tag: React.FC<Props> = ({ children }) => {
  return <MyTag>{children}</MyTag>;
};
export default Tag;

const MyTag = styled.div`
  ${fonts("B5")};
  padding: 6px 12px;
  color: ${colors.GRAY4};
  background-color: ${colors.GRAY10};
  border-radius: 50px;
`;
