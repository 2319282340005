import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

export interface Label {
  label: string;
  value: ReactNode;
}

interface Props {
  items: Label[];
  itemMargin?: string;
  labelWidth?: string;
}

const InfoLabelGroup: React.FC<Props> = ({
  items,
  itemMargin = "16px",
  labelWidth = "154px",
}) => {
  return (
    <InfoGroupWrap margin={itemMargin}>
      {items.map((item) => {
        if (!item.value) return null;
        return (
          <InfoLabelWrap key={item.label}>
            <InfoLabel style={{ width: labelWidth }}>{item.label}</InfoLabel>
            <InfoValue>{item.value}</InfoValue>
          </InfoLabelWrap>
        );
      })}
    </InfoGroupWrap>
  );
};
export default InfoLabelGroup;

const InfoGroupWrap = styled.div<{ margin: string }>`
  > div + div {
    margin-top: ${({ margin }) => margin};
  }
`;
const InfoLabelWrap = styled.div`
  display: flex;
`;
const InfoLabel = styled.div`
  flex-shrink: 0;
  ${fonts("B4")};
  color: ${colors.GRAY5};
`;
const InfoValue = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  ${fonts("B3")};
  color: ${colors.GRAY3};
`;
