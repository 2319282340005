import { css, FlattenSimpleInterpolation } from "styled-components";

type Font =
  | "D1"
  | "D2"
  | "H1"
  | "H2"
  | "H3"
  | "H4"
  | "H5"
  | "H6"
  | "H7"
  | "B1"
  | "B2"
  | "B3"
  | "B4"
  | "B5"
  | "B6"
  | "PIE";
export const fonts = (font: Font): FlattenSimpleInterpolation => {
  switch (font) {
    case "H1":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 42px;
      `;
    case "H2":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 32px;
      `;
    case "H3":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 24px;
      `;
    case "H4":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 18px;
      `;
    case "H5":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 16px;
      `;
    case "H6":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 14px;
      `;
    case "H7":
      return css`
        line-height: 150%;
        font-weight: 700;
        font-size: 10px;
      `;
    case "D1":
      return css`
        font-family: "NanumGothic", -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
          "Helvetica Neue", sans-serif;
        line-height: 120%;
        font-weight: 700;
        font-size: 14px;
      `;
    case "D2":
      return css`
        font-family: "NanumGothic", -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
          "Helvetica Neue", sans-serif;
        line-height: 120%;
        font-weight: 700;
        font-size: 48px;
      `;
    case "B1":
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 24px;
      `;
    case "B2":
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 18px;
      `;
    case "B3":
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 16px;
      `;
    case "B4":
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 14px;
      `;
    case "B5":
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 12px;
      `;
    case "B6":
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 10px;
      `;
    case "PIE":
      return css`
        line-height: 18px;
        font-weight: 400;
        font-size: 8px;
      `;
    default:
      return css`
        line-height: 150%;
        font-weight: 400;
        font-size: 24px;
      `;
  }
};
