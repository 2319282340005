import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { PageWrap } from "../../components/Layout/styles";
import useChangePage from "../../hooks/useChangePage";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { ReactComponent as PlusIcon } from "../../assets/img/icon/Plus.svg";
import AddHeritageModal from "../../components/Modal/AddHeritageModal";
import ContentLayout from "../../components/ContentLayout";
import { WordCloudOption } from "../../components/WordCloud";
import WordCloudWithInfo from "../../components/WordCloudWithInfo";
import NGraph from "../../components/NGraph";
import HeritageListCard from "../../components/HeritageListCard";
import { DataSet, Node, Edge } from "vis-network/standalone";
import { IHeritageListItem } from "@apis/heritage/interface/response";
import { useMutation } from "react-query";
import { requestHeritageGraphList, requestHeritageWordCloudLists } from "@apis";
import { handlePlainCultureWordCloud } from "utils";

interface Props {}

const Network: React.FC<Props> = () => {
  const { handleChangePage } = useChangePage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHeritage, setSelectedHeritage] = useState<IHeritageListItem[]>(
    []
  );
  const resultRef = useRef<HTMLDivElement | null>(null);
  const [wordCloudData, setWordCloudData] = useState<WordCloudOption[]>([]);
  const [nodes, setNodes] = useState<DataSet<Node, "id">>();
  const [edges, setEdges] = useState<DataSet<Edge, "id">>();

  /** 문화자산 관계도 조회 API */
  const { mutate: getHeritageGraphList } = useMutation(
    requestHeritageGraphList,
    {
      onSuccess: (data) => {
        const { nodes, edges } = data;
        setNodes(nodes);
        setEdges(edges);
      },
    }
  );

  const { mutate: getHeritageWordCloud } = useMutation(
    requestHeritageWordCloudLists,
    {
      onSuccess: (data) => {
        const { rows } = data;
        setWordCloudData(handlePlainCultureWordCloud(rows));
      },
    }
  );

  // 모달 열기 함수
  const handleModalOpenClick = () => {
    setIsModalOpen(true);
  };
  // 모달 닫기 함수
  const handleModalCloseClick = () => {
    setIsModalOpen(false);
  };

  // 선택된 데이터에서 x 버튼 클릭 시 실행 함수
  const handleRemoveClick = (info: IHeritageListItem) => {
    setSelectedHeritage((prev) =>
      prev.filter((prevInfo) => prevInfo.id !== info.id)
    );
  };

  // 모달에서 선택완료를 했을 때 결과 영역으로 스크롤 이동해주는 함수
  const handleSelectedChange = (selectedList: IHeritageListItem[]) => {
    if (resultRef.current) {
      document.documentElement.scrollTo({
        top: resultRef.current.offsetTop - 80,
        left: 0,
        behavior: "smooth",
      });
    }
    setSelectedHeritage(selectedList);
  };

  // 모달에서 선택한 데이터를 선택된 데이터 카드에 넣어주는 함수
  const renderCardList = () => {
    const render: ReactNode[] = [];
    for (let i = 0; i < selectedHeritage.length; i++) {
      render.push(
        <HeritageListCard
          isLink={false}
          key={selectedHeritage[i].id}
          info={selectedHeritage[i]}
          onRemove={handleRemoveClick}
        />
      );
    }
    for (let i = 0; i < 3 - selectedHeritage.length; i++) {
      render.push(
        <EmptyCard onClick={handleModalOpenClick} key={`empty${i}`}>
          <PlusIcon fill="#96989C" stroke="#96989C" />
          <span>비교대상을 추가 해주세요</span>
        </EmptyCard>
      );
    }
    return render;
  };

  // 초기화 버튼 클릭 시 실행 함수
  const handleResetClick = () => {
    setSelectedHeritage([]);
    setNodes([]);
    setEdges([]);
    setWordCloudData([]);
  };

  /** 선택된 문화자산 변경이 생길 시 */
  useEffect(() => {
    const ids = selectedHeritage
      ?.map?.((item) => {
        return item.id;
      })
      .join(",");
    if (selectedHeritage?.length > 0) {
      getHeritageGraphList({ id: ids });
      getHeritageWordCloud({ id: ids });
    }
  }, [getHeritageGraphList, getHeritageWordCloud, selectedHeritage]);

  return (
    <Layout selectedMenu="network" onChangePage={handleChangePage}>
      <PageWrap>
        <NetworkPageWrap>
          <NetworkPageTitle>
            <h2>자료검색</h2>
            <div>네트워크 정보 비교를 진행할 자료를 검색할 수 있습니다. </div>
          </NetworkPageTitle>
          <SelectedHeritageWrap>
            <SelectedHeritageHeader>
              <SubTitle>비교 문화유산</SubTitle>
              <ResetButton onClick={handleResetClick}>초기화</ResetButton>
            </SelectedHeritageHeader>
            <SelectedHeritageCardList>
              {renderCardList()}
            </SelectedHeritageCardList>
          </SelectedHeritageWrap>
          <CytosCapeWrap ref={resultRef}>
            <ContentLayout>
              <SubTitle>관계 분석 결과</SubTitle>
              <NGraph nodes={nodes} edges={edges} />
            </ContentLayout>
          </CytosCapeWrap>
          <WordCloudWithInfo data={wordCloudData} />
        </NetworkPageWrap>
      </PageWrap>
      <AddHeritageModal
        visible={isModalOpen}
        onClose={handleModalCloseClick}
        onSelectedChange={handleSelectedChange}
        selectedList={selectedHeritage}
      />
    </Layout>
  );
};
export default Network;

const NetworkPageWrap = styled.div`
  padding-top: 42px;
`;
const NetworkPageTitle = styled.div`
  ${fonts("B3")};
  h2 {
    ${fonts("H3")};
    margin-bottom: 12px;
  }
`;
const SelectedHeritageWrap = styled.div`
  margin-top: 37px;
`;
const SelectedHeritageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SelectedHeritageCardList = styled.div`
  margin-top: 24px;
  > * + * {
    margin-top: 24px;
  }
`;
const CytosCapeWrap = styled.div`
  margin-top: 40px;
`;

const SubTitle = styled.h3`
  ${fonts("H3")};
`;
const EmptyCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 16px;
  height: 145px;
  background: ${colors.WHITE};
  color: ${colors.GRAY5};
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
`;
const ResetButton = styled.div`
  cursor: pointer;
  ${fonts("B3")};
`;
