import React, { forwardRef } from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";

interface CustomGoogleMapRef {
  addClickListener: (data: any) => void;
  addMouseoverListener: (data: any) => void;
  addMouseoutListener: (data: any) => void;
}

const CustomGoogleMap = forwardRef<
  CustomGoogleMapRef,
  GoogleMapProps & { isLoaded: boolean }
>((props, ref) => {
  const { isLoaded } = props;

  // useImperativeHandle(ref, () => ({
  //   addClickListener: () => {},
  //   addMouseoverListener: () => {},
  //   addMouseoutListener: () => {},
  // }));
  return isLoaded ? (
    <GoogleMap
      {...props}
      options={{
        ...props.options,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      }}
    >
      {props.children}
    </GoogleMap>
  ) : null;
});

export default CustomGoogleMap;
