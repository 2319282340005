import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "../../assets/img/icon/logo.svg";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import AiAvatar from "@assets/img/ai-avatar.png";
import dayjs from "dayjs";
import Button from "@components/Button";
import { ReactComponent as CloseIcon } from "../../assets/img/icon/Close.svg";
import useAiComu from "services/ai-comu";
import { useMutation } from "react-query";
import Lottie from "react-lottie";
import LottieLoading from "@assets/lottie/loading.json";

export type Page =
  | "heritage"
  | "project"
  | "network"
  | "place"
  | "vrdimen"
  | "aicomu";
export type Color = "black" | "white";

const pageString: { [key in Page]: Page } = {
  heritage: "heritage",
  project: "project",
  network: "network",
  place: "place",
  aicomu: "aicomu",
  vrdimen: "vrdimen",
};

interface Props {
  children: ReactNode;
  onChangePage?: (page: Page) => void;
  selectedMenu?: Page;
}

const Layout: React.FC<Props> = ({
  children,
  onChangePage = () => {},
  selectedMenu,
}) => {
  const navigation = useNavigate();

  const handleClickHome = () => {
    navigation("/#page1");
  };

  const [keyword, setKeyword] = useState<string>("");
  const { chat, setChat, setAiMessage, clearMessage } = useAiComu(
    (state) => state
  );
  const [isChatModalVisible, setIsChatModalVisible] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatWrapRef = useRef<HTMLDivElement | null>(null);

  const [output, setOutput] = useState<string>();

  const { mutateAsync: getOpenAiMessages, isLoading } = useMutation(
    async (content: { message: string; index?: number }) => {
      const { message, index } = content;
      let accumulatedOutput = "";
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ROOT_URL}open-ai/chunk-messages`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ message }),
          }
        );
        if (response.ok) {
          const textDecoder = new TextDecoder();
          const reader = response?.body?.getReader();

          if (reader)
            while (true) {
              const { done, value } = await reader?.read();

              if (done) {
                setOutput("");
                setAiMessage([
                  {
                    type: "ai",
                    message: accumulatedOutput,
                    createdDate: dayjs().locale("ko").format("A hh:mm"),
                    id: (index || chat.length) + 1,
                    status: "complete",
                  },
                ]);
                break;
              }

              // Uint8Array를 문자열로 디코딩
              const decodedString = textDecoder.decode(value);
              accumulatedOutput += decodedString;
              setOutput(accumulatedOutput);
              if (typeof accumulatedOutput === "string") {
                setAiMessage([
                  {
                    type: "ai",
                    message: accumulatedOutput,
                    createdDate: dayjs().locale("ko").format("A hh:mm"),
                    id: (index || chat.length) + 1,
                    status: "read",
                  },
                ]);
              }
            }

          return accumulatedOutput;
        } else {
          const itIsError = await response.json();
          setAiMessage([
            {
              type: "error",
              message: itIsError.message || "다시 시도해주세요.",
              createdDate: dayjs().locale("ko").format("A hh:mm"),
              id: chat.length,
              status: "complete",
            },
          ]);
        }
      } catch (e: any) {
        if (!e.status) {
          setAiMessage([
            {
              type: "ai",
              message: accumulatedOutput,
              createdDate: dayjs().locale("ko").format("A hh:mm"),
              id: (index || chat.length) + 1,
              status: "complete",
            },
          ]);
        }
        throw e;
      }
    },
    {
      // onSuccess: (data) => {
      //   setOutput("");
      // },
      onError: (error: any) => {
        if (error.status) {
          setAiMessage([
            {
              type: "error",
              message: error.message || "다시 시도해주세요.",
              createdDate: dayjs().locale("ko").format("A hh:mm"),
              id: chat.length,
              status: "complete",
            },
          ]);
        }
      },
    }
  );

  const handleResizeHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current?.scrollHeight - 20 + "px";
    }
    setTimeout(() => {
      if (chatContainerRef.current && textareaRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  const handleKeywordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setKeyword(e.target.value);
  };

  const handleResult = (keyword: string) => {
    if (keyword !== "") {
      setChat([
        {
          type: "user",
          message: keyword,
          createdDate: dayjs().locale("ko").format("A hh:mm"),
          id: chat.length,
        },
      ]);
      getOpenAiMessages({ message: keyword });
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = "28px";
      }
      setKeyword("");
    }
  };

  const onCloseChat = () => {
    setIsChatModalVisible(false);
  };

  const onShowChat = () => {
    setIsChatModalVisible(true);
    setTimeout(() => {
      if (chatContainerRef.current && textareaRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  useEffect(() => {
    if (chatContainerRef.current && textareaRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chat, output]);

  return (
    <LayoutStyle>
      <LayoutHeaderWrap>
        <LayoutHeader>
          <LogoArea onClick={handleClickHome}>
            <Logo width={24} height={24} />
            <h1>Korean Cultural Heritage Resources</h1>
          </LogoArea>
          <nav>
            <NavWrap>
              <NavItem
                isSelected={selectedMenu === pageString.project}
                onClick={() => onChangePage(pageString.project)}
              >
                프로젝트 소개
              </NavItem>
              <NavItem
                isSelected={selectedMenu === pageString.heritage}
                onClick={() => onChangePage(pageString.heritage)}
              >
                문화자산
              </NavItem>
              <NavItem
                isSelected={selectedMenu === pageString.place}
                onClick={() => onChangePage(pageString.place)}
              >
                공간정보
              </NavItem>
              <NavItem
                isSelected={selectedMenu === pageString.network}
                onClick={() => onChangePage(pageString.network)}
              >
                네트워크
              </NavItem>
              <NavItem
                isSelected={selectedMenu === pageString.aicomu}
                onClick={() => onChangePage(pageString.aicomu)}
              >
                AI 대화
              </NavItem>
              <NavItem
                isSelected={selectedMenu === pageString.vrdimen}
                onClick={() => onChangePage(pageString.vrdimen)}
              >
                가상공간
              </NavItem>
            </NavWrap>
          </nav>
        </LayoutHeader>
      </LayoutHeaderWrap>
      <MainWrap>{children}</MainWrap>

      {selectedMenu !== "aicomu" && (
        <>
          {!isChatModalVisible && (
            <AiAvatarWrap
              data-aos="fade-up"
              style={{
                position: "fixed",
                bottom: 126,
                right: 20,
                zIndex: 999,
                cursor: "pointer",
                boxShadow: "0px 8px 12px 0px rgba(60, 62, 66, 0.25)",
              }}
              onClick={onShowChat}
            >
              <img src={AiAvatar} alt="ai-avatar" />
            </AiAvatarWrap>
          )}
          {isChatModalVisible && (
            <div
              data-aos="fade-up"
              style={{
                position: "fixed",
                bottom: 126,
                right: 20,
                zIndex: 999,
                width: "455px",
                background: "#5AA88A",
                display: "flex",
                flexDirection: "column",
                borderRadius: 16,
                overflow: "hidden",
                boxShadow: "0px 8px 12px 0px rgba(60, 62, 66, 0.25)",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  padding: "16px 24px",
                  justifyContent: "flex-end",
                }}
              >
                <ModalCloseButton fill="#fff" onClick={onCloseChat} />
              </div>
              <ChatWrap id="chat-wrap">
                <Button
                  style={{ borderRadius: 0 }}
                  onClick={() => clearMessage()}
                >
                  초기화
                </Button>
                <ChatWrap2
                  ref={chatWrapRef}
                  height={textareaRef.current?.style.height ?? "28px"}
                >
                  <ChatContainer ref={chatContainerRef} id="chat-form">
                    {chat.map((item) => {
                      if (item.type === "ai") {
                        return (
                          <ChatAIMessageWrap key={item.id}>
                            <AiAvatarWrap>
                              <img src={AiAvatar} alt="ai-avatar" />
                            </AiAvatarWrap>
                            <ChatAIMessage>
                              <MessageP>{item.message}</MessageP>
                            </ChatAIMessage>
                            <ChatTime>{item.createdDate}</ChatTime>
                          </ChatAIMessageWrap>
                        );
                      }

                      if (item.type === "error") {
                        return (
                          <ChatAIMessageWrap key={item.id}>
                            <AiAvatarWrap>
                              <img src={AiAvatar} alt="ai-avatar" />
                            </AiAvatarWrap>
                            <ChatAIMessage
                              style={{ border: `1px solid ${colors.RED}` }}
                            >
                              <MessageP style={{ color: colors.RED }}>
                                {item.message}
                              </MessageP>
                            </ChatAIMessage>
                            <ChatTime>{item.createdDate}</ChatTime>
                          </ChatAIMessageWrap>
                        );
                      }

                      return (
                        <ChatUserMessageWrap key={item.id}>
                          <ChatUserMessage>
                            <MessageP>{item.message}</MessageP>
                          </ChatUserMessage>
                          <ChatTime>{item.createdDate}</ChatTime>
                        </ChatUserMessageWrap>
                      );
                    })}
                    {isLoading && !output && (
                      <ChatAIMessageWrap>
                        <AiAvatarWrap>
                          <img src={AiAvatar} alt="ai-avatar" />
                        </AiAvatarWrap>
                        <ChatAIMessage>
                          <MessageP style={{ height: 24 }}>
                            <Lottie
                              options={{ animationData: LottieLoading }}
                            />{" "}
                          </MessageP>
                        </ChatAIMessage>
                        <ChatTime>
                          {dayjs().locale("ko").format("A hh:mm")}
                        </ChatTime>
                      </ChatAIMessageWrap>
                    )}
                    {/* {output && (
                      <ChatAIMessageWrap>
                        <AiAvatarWrap>
                          <img src={AiAvatar} alt="ai-avatar" />
                        </AiAvatarWrap>
                        <ChatAIMessage>
                          <MessageP>{output}</MessageP>
                        </ChatAIMessage>
                        <ChatTime>
                          {dayjs().locale("ko").format("A hh:mm")}
                        </ChatTime>
                      </ChatAIMessageWrap>
                    )} */}
                  </ChatContainer>
                </ChatWrap2>
                <ChatSubmitForm
                  id="chat-submit"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleResult(keyword);
                  }}
                >
                  <ChatTextarea
                    rows={1}
                    ref={textareaRef}
                    value={keyword}
                    onChange={(e) => {
                      handleResizeHeight();
                      handleKeywordChange(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.shiftKey == false) {
                        e.preventDefault();
                        if (textareaRef.current) {
                          handleResult(keyword);
                        }
                      }
                    }}
                  />
                  <Button type="submit" height="48px" width="83">
                    질문하기
                  </Button>
                </ChatSubmitForm>
              </ChatWrap>
            </div>
          )}
        </>
      )}
      <LayoutFooter>
        <FooterContentWrap>
          <div>
            저작권은 지역역사문화자산의 재해석을 위한 AI-아카이브 플랫폼
            연구팀에 있습니다.
            <br />이 웹사이트는 한국연구재단의 지원을 받아 제작되었습니다.
          </div>
          <div>Copyright all reserved.</div>
        </FooterContentWrap>
      </LayoutFooter>
    </LayoutStyle>
  );
};
export default Layout;

const LayoutStyle = styled.div`
  min-width: 1280px;
  min-height: 100%;
  background-color: #f1f3f6;
`;
const MainWrap = styled.main`
  height: 100%;
  perspective: 100px;
`;
const LayoutHeaderWrap = styled.div`
  width: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 10;
  transform: translateX(-50%);
  background-color: #f1f3f6;
  z-index: 101;
`;
const LayoutHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  max-width: 1280px;
  padding: 0 80px;
  margin: 0 auto;
  color: black;
`;
const LayoutFooter = styled.footer`
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  background-color: #f1f3f6;
  border-top: 1px solid #e8e8e8;
  z-index: 100;
`;
const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  padding: 0 80px;
  max-width: 1280px;
`;
const LogoArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  h1 {
    ${fonts("D1")};
    color: ${colors.GRAY1};
  }
  cursor: pointer;
`;
const NavWrap = styled.ul`
  display: flex;
  gap: 0 40px;
  color: white;

  li {
    cursor: pointer;
    width: fit-content;
  }
`;

const NavItem = styled.div<{
  isSelected: boolean;
}>`
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${colors.GRAY1};
          ${fonts("H6")}
        `
      : css`
          color: ${colors.GRAY6};
          ${fonts("B4")}
        `};
  cursor: pointer;
`;

const ChatWrap = styled.section`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  overflow: hidden;
  position: relative;
`;

const ChatWrap2 = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;
  min-height: calc(
    100vh -
      ${({ height }) =>
        `${Number(height.replace("px", "")) >= 200 ? "200px" : height}`} - 355px
  );
  max-height: calc(
    100vh -
      ${({ height }) =>
        `${Number(height.replace("px", "")) >= 200 ? "200px" : height}`} - 355px
  );
  width: 100%;
`;

const ChatSubmitForm = styled.form`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 0 16px;
  padding: 16px 16px 16px 24px;
  border-top: solid 1px ${colors.GRAY7};
  background: ${colors.WHITE};
`;

const ChatTextarea = styled.textarea`
  width: 100%;
  max-height: 200px;
  border: 0;
  ${fonts("H4")};
  // height: 100%;
  padding: 10px;
  -webkit-tap-highlight-color: transparent;
  resize: none;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 36px 24px;
  overflow: auto;
  max-height: calc(100% - 80px);
`;

const ChatUserMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
  align-items: flex-end;
  gap: 4px;
`;

const ChatUserMessage = styled.div`
  padding: 8px 16px;
  background: ${colors.GRAY10};
  border-radius: 20px;
`;

const ChatTime = styled.div`
  padding: 4px 0px;
  white-space: nowrap;
`;

const ChatAIMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

const ChatAIMessage = styled.div`
  padding: 8px 16px;
  background: ${colors.WHITE};
  border-radius: 20px;
  border: 1px solid ${colors.GRAY8};
`;

const AiAvatarWrap = styled.div`
  background: linear-gradient(90deg, #5aa88a 0%, #7cc4aa 100%);
  border-radius: 20px;
  display: flex;
  padding: 4px;
  margin-right: 4px;
`;

const MessageP = styled.p`
  white-space: pre-wrap;
  word-break: break-word;
`;

const ModalCloseButton = styled(CloseIcon)`
  cursor: pointer;
  & > path {
    fill: #fff;
  }
`;
