import Layout from "@components/Layout";
import React from "react";
import useChangePage from "../../hooks/useChangePage";
import styled from "styled-components";

interface Props {}

const VrDimen: React.FC<Props> = () => {
  const { handleChangePage } = useChangePage();

  return (
    <Layout selectedMenu={"vrdimen"} onChangePage={handleChangePage}>
      <PageWrap>
        {/* <div
          id="vridimen-html"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div> */}
        <Iframe src="/vrdimen.html" style={{ position: "absolute" }} />
      </PageWrap>
    </Layout>
  );
};

export default VrDimen;

export const PageWrap = styled.div`
  margin: 0 auto;
  height: calc(100vh - 97px);
  position: realtive;
`;

export const Iframe = styled.iframe`
  width: calc(100% - 10px);
  top: 100px;
  height: calc(100vh - 185px);
`;
