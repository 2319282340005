import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { fonts } from "../../theme/fonts";
import { ReactComponent as UpIcon } from "../../assets/img/icon/Arrow-Up.svg";
import { ReactComponent as DownIcon } from "../../assets/img/icon/Arrow-down.svg";
import { colors } from "../../theme/colors";

interface Props {
  title: string;
  options: string[];
  onChange: (value: any) => void;
  values: any[];
  isDefaultOpen?: boolean;
}

const FilterCollapse: React.FC<Props> = ({
  title,
  options,
  onChange,
  values,
  isDefaultOpen = true,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const handleDownClick = () => {
    setIsOpen(true);
  };
  const handleUpClick = () => {
    setIsOpen(false);
  };

  const style = useMemo(
    () => ({
      style: { padding: "0" },
    }),
    []
  );

  return (
    <FilterCollpaseWrap>
      <FilterCollpaseHeader>
        {/* <FormControlLabel
          key={title}
          control={
            <Checkbox
              {...style}
              checked={values.includes(title)}
              onChange={() => onChange(title)}
            />
          }
          label={<FilterCollapseTitle>{title}</FilterCollapseTitle>}
        /> */}
        <FilterCollapseTitle>{title}</FilterCollapseTitle>
        {options.length > 0 ? (
          isOpen ? (
            <UpButton onClick={handleUpClick} />
          ) : (
            <DownButton onClick={handleDownClick} />
          )
        ) : null}
      </FilterCollpaseHeader>
      {isOpen && options.length > 0 && (
        <FilterOptionsWrap>
          {options.map((label) => (
            <FormControlLabel
              key={label}
              sx={{
                margin: 0,
              }}
              control={
                <Checkbox
                  {...style}
                  checked={values.includes(label)}
                  onChange={() => onChange(label)}
                />
              }
              label={label}
            />
          ))}
        </FilterOptionsWrap>
      )}
    </FilterCollpaseWrap>
  );
};
export default FilterCollapse;

const FilterCollpaseWrap = styled.div`
  padding: 12px 0;
  & + & {
    border-top: 1px solid ${colors.GRAY6};
  }
`;
const FilterCollpaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FilterOptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px 0;
  margin-left: 12px;
`;
const FilterCollapseTitle = styled.div`
  ${fonts("H5")}
`;
const UpButton = styled(UpIcon)`
  cursor: pointer;
`;
const DownButton = styled(DownIcon)`
  cursor: pointer;
`;
