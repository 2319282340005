import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HashPage } from ".";
import bg from "../../assets/bg/projectbg.jpg";
import IntroButton from "../../components/Button/IntroButton";

interface Props {
  hash: HashPage;
}

const Introduction: React.FC<Props> = ({ hash }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const navigation = useNavigate();
  useEffect(() => {
    if (hash === "#page2") {
      setTimeout(() => {
        if (textRef.current) {
          textRef.current.style.animationName = "slideIn";
        }
      }, 200);
    }
  }, [hash]);
  const handleClickDetail = () => {
    navigation("/introduction");
  };
  return (
    <Background>
      <ProjectTitleWrap ref={textRef}>
        <ProjectTitle>프로젝트 소개</ProjectTitle>
        <ProjectInfo>
          본 연구는 역사문화자산 정보를 재해석하고 새로운 역사적 연계성과 가치를
          발견할 수 있는
          <br />
          “지역역사문화자산의 재해석을 위한 AI-아카이브 플랫폼” 구축을 목표로
          하고 있습니다.
          <br />
          이를 통해 지역성을 명확하게 정의하고 수용할 수 있는 정보의 네트워크를
          강화하고,
          <br />
          지역 공간 정보를 재편성하여 집단 기억에 기반한 지역성을 재해석 하고자
          합니다.
        </ProjectInfo>
        <IntroButton onClick={handleClickDetail}>더 알아보기</IntroButton>
      </ProjectTitleWrap>
    </Background>
  );
};
export default Introduction;

const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  background: no-repeat center/cover url(${bg});
`;

const ProjectTitleWrap = styled.div`
  width: 930px;
  max-width: 80%;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  opacity: 0;
  z-index: 5;
  @keyframes slideIn {
    from {
      top: 60%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
  color: white;
`;
const ProjectTitle = styled.h2`
  display: inline-block;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
`;
const ProjectInfo = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
`;
