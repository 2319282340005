export const routePath = {
  INDEX: "/",
  INTRODUCTION: "/introduction",
  HERITAGE: "/heritage",
  HERITAGE_DETAIL: "/heritage/:id",
  NETWORK: "/network",
  PLACE: "/place",
  AICOMU: "/aicomu",
  VRDIMEN: "/vrdimen",
};

export const PageSizeOption = [
  { label: "10개 보기", value: 10 },
  { label: "50개 보기", value: 50 },
  { label: "100개 보기", value: 100 },
];
