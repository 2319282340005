import React, { useMemo } from "react";
import ReactWordcloud, { Optional, Options } from "react-wordcloud";
import styled from "styled-components";

interface Props {
  onWordClick?: (data: any) => void;
  data: WordCloudOption[];
}

export interface WordCloudOption {
  text: string;
  value: number;
}

const WordCloud: React.FC<Props> = ({ onWordClick, data }) => {
  const getColor = (word: WordCloudOption) => {
    const { value } = word;
    if (value >= 5) {
      return "#448EF7";
    } else if (value >= 4) {
      return "#6A32C9";
    } else if (value >= 3) {
      return "#72C240";
    } else if (value >= 2) {
      return "#F0B041";
    } else if (value >= 1) {
      return "#E23D3A";
    } else {
      return "#E23D3A";
    }
  };

  const callbacks = useMemo(() => {
    return {
      getWordColor: getColor,
      onWordClick: onWordClick,
      // getWordTooltip: (word: WordCloudOption) =>
      //   `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
    };
  }, [onWordClick]);
  const options: Optional<Options> = {
    rotations: 0,
    // rotationAngles: [-90, 0],
    fontSizes: [10, 40],
    deterministic: true,
    enableTooltip: false,
    // scale: "linear",
    scale: "sqrt",
  };

  // const size: [number, number] = [585, 500];
  const size: [number, number] = [585, 200];
  return (
    <CloudeWrap>
      <ReactWordcloud
        words={data}
        callbacks={callbacks}
        options={options}
        size={size}
        minSize={[200, 200]}
      />
    </CloudeWrap>
  );
};
export default React.memo(WordCloud);

const CloudeWrap = styled.div`
  margin-top: 24px;
  height: 587px;
  display: flex;
  & > div {
    display: flex;
    align-items: center;
  }
`;
