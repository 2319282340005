import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from ".";
import { ReactComponent as Left } from "../../assets/img/icon/left.svg";
import { ReactComponent as Right } from "../../assets/img/icon/right.svg";
import AccessImage from "../../assets/img/access-image.png";
import MyButton from "../Button";
import { colors } from "../../theme/colors";

interface Props {
  onClose: () => void;
  visible: boolean;
  images: string[];
  nowIndex: number;
  param: number;
}
interface WH {
  width: number;
  height: number;
}
const ImageModal: React.FC<Props> = ({
  visible,
  onClose,
  images,
  nowIndex,
  param,
}) => {
  const [index, setIndex] = useState<number>(nowIndex);
  const ref = useRef<HTMLImageElement>(null);
  const [imageList, setImageList] = useState(images);
  const [nowWh, setNowwh] = useState<WH>({ width: 0, height: 0 });
  const [nowName, setNowName] = useState<string>("");
  const [nowSize, setNowSize] = useState<string>("");
  const handleClickRight = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (index < images.length - 1) setIndex(index + 1);
  };
  const handleClickLeft = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (index > 0) setIndex(index - 1);
    else {
      setIndex(0);
    }
  };
  const getImageSize = async () => {
    if (ref.current) {
      const data = await fetch(ref.current.src, { mode: "no-cors" });
      const blob = await data.blob();
      const reader = new FileReader();
      reader.onload = () => {
        const fileSize = blob.size / (1024 * 1024);
        setNowSize(fileSize.toFixed(1));
      };
      reader.readAsDataURL(blob);
    }
  };
  const getOriginImageUrl = (imageUrl: string = "") => {
    return imageUrl.replace("l_", "");
  };
  useEffect(() => {
    setIndex(nowIndex);
  }, [nowIndex]);

  return (
    <Modal visible={visible} onClick={onClose}>
      <ModalWrap>
        <ImageWrap>
          <PrevButton
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={handleClickLeft}
            style={{ cursor: "pointer" }}
          >
            <PrevIcon />
          </PrevButton>
          <img
            ref={ref}
            id="detailModalImage"
            src={images[index]}
            onLoad={(event) => {
              setNowwh({
                height: event.currentTarget.naturalHeight,
                width: event.currentTarget.naturalWidth,
              });
              setNowName(
                event.currentTarget.src.split("/")[
                  event.currentTarget.src.split("/").length - 1
                ]
              );
              getImageSize();
            }}
            alt="상세"
          />
          <NextButton
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={handleClickRight}
            style={{ cursor: "pointer" }}
          >
            <NextIcon />
          </NextButton>
        </ImageWrap>
        <FooterWrap>
          <ImageInfoWrap>
            <div>파일명: {nowName}</div>
            <div>해상도: {nowWh.width + " X " + nowWh.height}px</div>
            <div>파일크기: {Number(nowSize).toFixed(1)}mb</div>
          </ImageInfoWrap>
          <SquareWrap>
            <img src={AccessImage} alt="접근성" width={165} height={52} />

            <ButtonWrap>
              <a
                href={getOriginImageUrl(images[index])}
                target="_blank"
                rel="noreferrer"
                download
              >
                <MyButton height="52px" width="165px">
                  내려받기
                </MyButton>
              </a>
            </ButtonWrap>
          </SquareWrap>
        </FooterWrap>
      </ModalWrap>
    </Modal>
  );
};
export default ImageModal;
const ModalWrap = styled.div`
  width: 900px;
  max-height: 90vh;
  min-height: 90vh;
  background-color: white;
  overflow: hidden;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
`;
const ButtonWrap = styled.div`
  width: 120px;
  height: 52px;
`;
const ImageWrap = styled.div`
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 16px;
  height: 80vh;
  img {
    max-width: 100%;
    height: 100%;
  }
`;
const ImageInfoWrap = styled.div`
  color: ${colors.GRAY3};
  font-size: 14px;
  display: flex;
  flex-direction: column;

  font-weight: 700;
`;
const FooterWrap = styled.div`
  flex: 0 0 80px;
  padding: 24px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const ArrowWrap = styled.div`
  background-color: white;
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SquareWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  right: 40px;
`;
const PrevButton = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 50%;
  border: 1px solid ${colors.GRAY3};
  cursor: pointer;
  background-color: ${colors.WHITE};
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;
const PrevIcon = styled(Left)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
`;
const NextButton = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 50%;
  border: 1px solid ${colors.GRAY3};
  cursor: pointer;
  background-color: ${colors.WHITE};
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;
const NextIcon = styled(Right)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
`;
