/**
 * @author 무무(염재헌)
 * @since 2023-09-22
 * @description 문화자산 관련 API Request
 * @name 문화자산
 * @규칙 ------------------------------
 * @rolue
 * @postUpdate request{requestName}Update~
 * @getList request{requestName}Lists
 * @getDetail request{requestName}Detail
 * @delete request{requestName}Delete
 * @putUpdate request{requestName}Update~
 */

import { uniqueCheckArray } from "utils";
import { Row, Rows } from "../index-common";
import {
  IRequeestHeritageList,
  IRequestHeritageAsstesDetail,
} from "./interface/request";
import {
  IResponseHeritageAsstesDetail,
  IResponseHeritageGraphLists,
  IResponseHeritageWordCloudLists,
  IResponseCustomHeritageGraphLists,
  IResponseHeritageList,
  IResponseHeritageRelatedList,
} from "./interface/response";
import httpRequest from "@networks/index";

const ApiUrls = {
  heritageList: "/heritage-resources",
  heritageDetail: "/heritage-resources/{id}",
  heritageRelations: "/heritage-resources/{id}/relations",
  heritageWordCloud: "/heritage-resources/{id}/word-clouds",
  heritageCategory: "/heritage-resources/{id}/categories",
  heritageRelatedList: "/heritage-resources/{id}/related-list",
};

/** 문화자산 리스트 조회 */
export const requestHeritageList = async (
  params: IRequeestHeritageList
): Promise<Row<IResponseHeritageList>> => {
  const { data } = await httpRequest<Row<IResponseHeritageList>>(
    "GET",
    ApiUrls.heritageList,
    params
  );

  return data;
};

/** 문화자산 상세 조회 */
export const requestHeritageDetail = async (
  params: IRequestHeritageAsstesDetail
): Promise<Row<IResponseHeritageAsstesDetail>> => {
  const { id, ...rest } = params;
  const { data } = await httpRequest<Row<IResponseHeritageAsstesDetail>>(
    "GET",
    ApiUrls.heritageDetail.replace("{id}", encodeURIComponent(id)),
    rest
  );

  return data;
};

/** 문화자산 워드 클라우드 */
export const requestHeritageWordCloudLists = async (
  params: IRequestHeritageAsstesDetail
): Promise<Rows<IResponseHeritageWordCloudLists>> => {
  const { id, ...rest } = params;

  const { data } = await httpRequest<Rows<IResponseHeritageWordCloudLists>>(
    "GET",
    ApiUrls.heritageWordCloud.replace("{id}", encodeURIComponent(id)),
    rest
  );
  return data;
  // return new Promise((resolve) => resolve(data));
};

/** 문화자산 워드 클라우드 카테고리 */
export const requestHeritageWordCloudCategories = async (
  params: IRequestHeritageAsstesDetail
): Promise<Rows<string>> => {
  const { id, ...rest } = params;
  const { data } = await httpRequest<Rows<string>>(
    "GET",
    ApiUrls.heritageCategory.replace("{id}", encodeURIComponent(id)),
    rest
  );

  return data;
};

/** 문화자산 관계도 */
export const requestHeritageGraphList = async (
  params: IRequestHeritageAsstesDetail
): Promise<IResponseCustomHeritageGraphLists> => {
  const { id, ...rest } = params;
  const { data } = await httpRequest<Rows<IResponseHeritageGraphLists>>(
    "GET",
    ApiUrls.heritageRelations.replace("{id}", encodeURIComponent(id)),
    rest
  );
  const nodes: {
    id: string | number;
    label: string | number;
    color?: string;
  }[] = [];
  data.rows.forEach((item) => {
    nodes.push(
      {
        id: item.o.value
          .replace("http://www.kchr.com/KCHROntology#", "")
          .replaceAll("_", " "),
        label: item.o.value
          .replace("http://www.kchr.com/KCHROntology#", "")
          .replaceAll("_", " "),
        color: item.o.isMain ? "#05060A" : undefined,
      },
      {
        id: item.s.value
          .replace("http://www.kchr.com/KCHROntology#", "")
          .replaceAll("_", " "),
        label: item.s.value
          .replace("http://www.kchr.com/KCHROntology#", "")
          .replaceAll("_", " "),
        color: item.s.isMain ? "#05060A" : undefined,
      }
    );
  });

  const newNodes = uniqueCheckArray(nodes, "id");

  const edges: {
    from: string | number;
    label: string | number;
    to: string | number;
  }[] = data.rows.map((item) => ({
    from: item.s.value
      .replace("http://www.kchr.com/KCHROntology#", "")
      .replaceAll("_", " "),
    label: item.p.value
      .replace("http://www.kchr.com/KCHROntology#", "")
      .replaceAll("_", " "),
    to: item.o.value
      .replace("http://www.kchr.com/KCHROntology#", "")
      .replaceAll("_", " "),
  }));
  return new Promise((resolve) => resolve({ nodes: newNodes, edges }));
};

/** 문화자산 관련 자료 리스트 */
export const requestHeritageRelatedList = async (
  params: IRequestHeritageAsstesDetail
): Promise<Rows<IResponseHeritageRelatedList>> => {
  const { id } = params;
  const { data } = await httpRequest<Rows<IResponseHeritageRelatedList>>(
    "GET",
    ApiUrls.heritageRelatedList.replace("{id}", encodeURIComponent(id))
  );

  return data;
};
