export const colors = {
  GRAY1: "#000000",
  GRAY2: "#282A2E",
  GRAY3: "#3C3E42",
  GRAY4: "#5A5C60",
  GRAY5: "#96989C",
  GRAY6: "#BEC0C4",
  GRAY7: "#DDDFE2",
  GRAY8: "#E7E9EC",
  GRAY9: "#ECEEF1",
  GRAY10: "#F1F3F6",
  GRAY11: "#F6F8FB",
  WHITE: "#FFFFFF",
  RED: "#EF0000",
  PIE_RED: "#DF2B2E",
  PIE_ORANGE: "#E16623",
  PIE_YELLOW: "#E7C64B",
  PIE_GREEN: "#5EB692",
  PIE_LIGHTBLUE: "#6393F2",
  PIE_SKYBLUE: "#95C3F4",
  PIE_BLUE: "#4858E3",
  PIE_PURPLE: "#AD91DD",
  PIE_PINK: "#FFCFCF",
  PIE_BROWN: "#A86500",
  SELECTED_BLUE: "#0066FF",
};
